import { Grid, Table, TableBody } from '@mui/material';
import { parse } from 'qs';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { EIncludeExcludeName } from '@bvi/api-interfaces/entity/case-property';
import { ICaseStatisticsData } from '@bvi/api-interfaces/response/case';
import { ISettingsData } from '@bvi/api-interfaces/response/case-settings';
import { DataPopover, Div } from '@bvi/common-components';

import { ISearchFormDataSchema } from '../../lib';
import { i18nInstance } from '../../locales';

import { ResultRow } from './ResultRow';
import { ResultsTableHeader } from './ResultsTableHeader';
import { Title } from './ResultsTableTitle';
import { styles } from './styles';

type ResultsTableProperties = {
  data: ICaseStatisticsData;
  tagData?: ISettingsData;
  isLoading?: boolean;
  shouldSticky?: boolean;
  showGeometricMean?: boolean;
};

export const ResultsTable: React.FC<ResultsTableProperties> = (properties) => {
  const {
    data,
    isLoading,
    tagData,
    shouldSticky = false,
    showGeometricMean = false,
  } = properties;

  const { t } = useTranslation('', {
    i18n: i18nInstance,
    keyPrefix: 'results.table',
  });

  const location = useLocation();

  const [classActionTag, setClassActionTag] = useState<string>(
    `${EIncludeExcludeName.INCLUDE}ing`,
  );

  const searchParameters = parse(location.search, {
    comma: true,
    ignoreQueryPrefix: true,
  }) as Record<keyof ISearchFormDataSchema, string>;

  const headerCells = [
    {
      label: '',
    },
    {
      label: t('overallAverage'),
    },
    {
      label: t('median'),
    },
    {
      label: t('mode'),
    },
    {
      label: t('middleHalfMean'),
    },
    {
      label: t('lowestQuartileAverage'),
    },
    {
      label: t('highestQuartileAverage'),
    },
  ];

  const results = {
    firstRow: {
      label: t('rowIncludesZeros'),
      cells: [
        {
          value: data.overallAverage,
        },
        {
          value: data.overallMedian,
        },
        {
          value: Number.NaN,
        },
        {
          value: data.middleHalfMean,
        },
        {
          value: data.lowestQuartileAverage,
        },
        {
          value: data.highestQuartileAverage,
        },
      ],
      total: data.total,
    },
    secondRow: {
      label: t('rowExcludesZeros'),
      cells: [
        {
          value: data.overallAverageNoZeros,
        },
        {
          value: data.overallMedianNoZeros,
        },
        {
          value: data.modeNoZeros,
        },
        {
          value: data.middleHalfMeanNoZeros,
        },
        {
          value: data.lowestQuartileAverageNoZeros,
        },
        {
          value: data.highestQuartileAverageNoZeros,
        },
      ],
      total: data.totalNoZeros,
    },
  };

  if (showGeometricMean) {
    headerCells.push({
      label: t('geometricMean'),
    });

    results.firstRow.cells.push({
      value: Number.NaN,
    });

    results.secondRow.cells.push({
      value: data.geometricMeanNoZeros,
    });
  }

  useEffect(() => {
    const hasClassAction = searchParameters.classAction;
    setClassActionTag(
      hasClassAction
        ? `${EIncludeExcludeName.EXCLUDE}ing`
        : `${EIncludeExcludeName.INCLUDE}ing`,
    );
  }, [tagData, searchParameters, t]);

  const wrapperStyles = shouldSticky ? styles.stickyWrapper : {};
  return (
    <Div sx={wrapperStyles}>
      <DataPopover
        title={<Title data={data} tag={classActionTag} isLoading={isLoading} />}
      >
        <Grid p={1}>
          <Table>
            <ResultsTableHeader cells={headerCells} />
            <TableBody>
              {Object.values(results).map((result) => (
                <ResultRow
                  key={result.label}
                  label={result.label}
                  cells={result.cells}
                  total={result.total}
                  isLoading={isLoading}
                />
              ))}
            </TableBody>
          </Table>
        </Grid>
      </DataPopover>
    </Div>
  );
};
