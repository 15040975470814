import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';

import {
  EIncludeExcludeKeys,
  EIncludeExcludeValue,
  LegalDetailsKeys,
  LegalDetailsPreparatorVariantsKey,
  StaticPropertyKey,
  YesNoVariantsKey,
} from '@bvi/api-interfaces/entity/case-property';

import { ISearchFormDataSchema } from '../types';

export const mapFormDataToSubmit = (data: ISearchFormDataSchema) => {
  const preparatorValue =
    data[LegalDetailsKeys.PREPARATOR] === YesNoVariantsKey.YES
      ? [LegalDetailsPreparatorVariantsKey.DENIED_ABUSE]
      : [
          LegalDetailsPreparatorVariantsKey.ADMITTED_ABUSE,
          LegalDetailsPreparatorVariantsKey.CONVICTED,
          LegalDetailsPreparatorVariantsKey.DECEASED_WHEN_ACCUSED,
          LegalDetailsPreparatorVariantsKey.OTHERWISE_DEEMED_GUILTY,
        ];
  const classActionValue =
    data[EIncludeExcludeKeys.CLASS_ACTION_NAME] === EIncludeExcludeValue.YES
      ? EIncludeExcludeValue.YES
      : null;
  const bankruptcyValue =
    data[EIncludeExcludeKeys.BANKRUPTCY_NAME] === EIncludeExcludeValue.YES
      ? EIncludeExcludeValue.YES
      : null;
  const compensationValue =
    data[EIncludeExcludeKeys.COMPENSATION_REDRESS_NAME] ===
    EIncludeExcludeValue.YES
      ? EIncludeExcludeValue.YES
      : null;

  const result = {
    ...data,
    [StaticPropertyKey.COUNTRY]: data[StaticPropertyKey.COUNTRY]?.map(
      (item) => item.id,
    ),
    [StaticPropertyKey.REGION]: data[StaticPropertyKey.REGION]?.map(
      (item) => item.id,
    ),
    [StaticPropertyKey.STATE]: data[StaticPropertyKey.STATE]?.map(
      (item) => item.id,
    ),
    [StaticPropertyKey.ORGANIZATION]: data[StaticPropertyKey.ORGANIZATION]?.id,
    [StaticPropertyKey.DIOCESE]: data[StaticPropertyKey.DIOCESE]?.id,
    [StaticPropertyKey.ORGANIZATION_TYPE]: data[
      StaticPropertyKey.ORGANIZATION_TYPE
    ]?.map((item) => item.id),
    [StaticPropertyKey.EXCEPTION]: data[StaticPropertyKey.EXCEPTION]?.map(
      (item) => item.id,
    ),
    [StaticPropertyKey.ORDER]: data[StaticPropertyKey.ORDER]?.map(
      (item) => item.id,
    ),
    [StaticPropertyKey.LIABILITY_DEFENSES]: data[
      StaticPropertyKey.LIABILITY_DEFENSES
    ]?.map((item) => item.id),
    [LegalDetailsKeys.PREPARATOR]: data[LegalDetailsKeys.PREPARATOR]
      ? preparatorValue
      : null,
    [StaticPropertyKey.RESOLUTION_TYPE]: data[
      StaticPropertyKey.RESOLUTION_TYPE
    ]?.map((item) => item.id),
    [StaticPropertyKey.JOB_TITLE]: data[StaticPropertyKey.JOB_TITLE]?.map(
      (item) => item.id,
    ),
    [EIncludeExcludeKeys.CLASS_ACTION_NAME]: data[
      EIncludeExcludeKeys.CLASS_ACTION_NAME
    ]
      ? classActionValue
      : null,
    [EIncludeExcludeKeys.BANKRUPTCY_NAME]: data[
      EIncludeExcludeKeys.BANKRUPTCY_NAME
    ]
      ? bankruptcyValue
      : null,
    [EIncludeExcludeKeys.COMPENSATION_REDRESS_NAME]: data[
      EIncludeExcludeKeys.COMPENSATION_REDRESS_NAME
    ]
      ? compensationValue
      : null,
  };

  return omitBy(result, (property) => isNil(property));
};
