import { SxProperties } from '@bvi/mui-theme';

export const styles = {
  canvasWrapper: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 20px',
    '& > canvas': {
      width: '100%!important',
      height: '300px!important',
    },
  },
  canvasSkeleton: {
    minHeight: '300px',
    width: '100%',
  },
  stickyWrapper: {
    position: 'sticky',
    top: '270px',
    backgroundColor: 'white',
    borderRadius: '10px',
    zIndex: 5,
  },
} satisfies SxProperties;
