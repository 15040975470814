import { parse } from 'qs';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { isServerError } from '@bvi/axios-query';
import {
  buildAgeCategoryQuery,
  ISearchFormDataSchema,
} from '@bvi/cases-search';
import { useLazyGetStatisticsQuery } from '@bvi/dashboard/entities/search/api-slice';
import { selectStatistic } from '@bvi/dashboard/entities/search/selectors';
import { useSearchNotification } from '@bvi/dashboard/feature/search/lib/useSearchNotification';
import { Search } from '@bvi/dashboard/widgets/search';

export const SearchPage: React.FC = () => {
  const location = useLocation();
  const searchParameters = parse(location.search, {
    comma: true,
    ignoreQueryPrefix: true,
  }) as Record<keyof ISearchFormDataSchema, string>;

  const [getStatistics, { isFetching, data: statistics }] =
    useLazyGetStatisticsQuery();
  const [getIncludeStatistics, { data: includeStatistics }] =
    useLazyGetStatisticsQuery();
  const { showSearchNotificationMessage } = useSearchNotification();

  const handleLoadStatistics = async () => {
    const ageCategoryQuery = buildAgeCategoryQuery(searchParameters);
    try {
      const updatedSearchParameters = {
        ...searchParameters,
        ...ageCategoryQuery,
      };

      await getIncludeStatistics({}, false).unwrap();
      await getStatistics(updatedSearchParameters, false).unwrap();
    } catch (error) {
      if (isServerError(error)) {
        showSearchNotificationMessage(error);
      }
    }
  };

  useEffect(() => {
    handleLoadStatistics();
  }, [location.search]);

  const statisticsData = selectStatistic(statistics);
  const includeStatisticsData = selectStatistic(includeStatistics);
  return (
    <Search
      statisticsData={statisticsData}
      isFetching={isFetching}
      includeStatisticsData={includeStatisticsData}
    />
  );
};
