import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import isEmpty from 'lodash/isEmpty';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  AbuseDetailsKeys,
  EIncludeExcludeKeys,
  LegalDetailsKeys,
  ResolutionPublicityVariantsKey,
  StaticPropertyKey,
  VictimInfoAgeCategoryVariantKeys,
  VictimInfoKeys,
  YesNoVariantsKey,
} from '@bvi/api-interfaces/entity/case-property';
import {
  ALLOWED_AMOUNTS,
  AdditionalFilterParameter,
} from '@bvi/api-interfaces/request/case';
import { Autocomplete, Div, Select, Select2 } from '@bvi/common-components';
import { useFormOptions } from '@bvi/form-utils';

import {
  ISearchFormDataSchema,
  OnOptionsLoad,
  formatResolutionRangeValue,
  refineAgeCategories,
  setValueAsNumber,
  useSearchContext,
} from '../../../lib';
import { i18nInstance } from '../../../locales';
import { styles } from '../styles';

interface IAdvancedSearchProperties {
  onOptionsLoad: OnOptionsLoad;
  isFormLoading?: boolean;
  profileExists: boolean;
}

const SETTLEMENT_AMOUNT_ITEMS = ALLOWED_AMOUNTS.map((amount) => ({
  id: String(amount),
  name: formatResolutionRangeValue(amount),
}));

export const AdvancedSearch: React.FC<IAdvancedSearchProperties> = (
  properties,
) => {
  const { onOptionsLoad, isFormLoading, profileExists } = properties;
  const { t } = useTranslation('', {
    i18n: i18nInstance,
    keyPrefix: 'form',
  });

  const { allowToSearchConfidential } = useSearchContext();
  const { control, register, watch, getValues } =
    useFormContext<ISearchFormDataSchema>();

  const publicityVariants = useFormOptions(
    ResolutionPublicityVariantsKey,
    AdditionalFilterParameter.RESOLUTION_PUBLICITY,
  );

  const parentAgeCategoryValue = watch(VictimInfoKeys.AGE_CATEGORY);
  const refineAgeCategoryOptions = useFormOptions(
    refineAgeCategories,
    'ageCategory',
    'ageCategoryDescription',
  );

  const filterRefineAgeCategoryOptions = (
    ageCategoryValue: Array<VictimInfoAgeCategoryVariantKeys>,
  ): typeof refineAgeCategoryOptions => {
    const ageCategoriesByParent: Partial<
      Record<
        VictimInfoAgeCategoryVariantKeys,
        Partial<Array<VictimInfoAgeCategoryVariantKeys>>
      >
    > = {
      [VictimInfoAgeCategoryVariantKeys.PREPUBESCENT]: [
        VictimInfoAgeCategoryVariantKeys.PREPUBESCENT_TO_ADULT,
        VictimInfoAgeCategoryVariantKeys.PREPUBESCENT_TO_TEEN,
      ],
      [VictimInfoAgeCategoryVariantKeys.TEEN]: [
        VictimInfoAgeCategoryVariantKeys.PREPUBESCENT_TO_TEEN,
        VictimInfoAgeCategoryVariantKeys.PREPUBESCENT_TO_ADULT,
        VictimInfoAgeCategoryVariantKeys.TEEN_TO_ADULT,
      ],
      [VictimInfoAgeCategoryVariantKeys.MINOR]: [
        VictimInfoAgeCategoryVariantKeys.PREPUBESCENT_TO_ADULT,
        VictimInfoAgeCategoryVariantKeys.TEEN_TO_ADULT,
        VictimInfoAgeCategoryVariantKeys.MINOR_TO_ADULT,
      ],
      [VictimInfoAgeCategoryVariantKeys.ADULT]: [
        VictimInfoAgeCategoryVariantKeys.PREPUBESCENT_TO_ADULT,
        VictimInfoAgeCategoryVariantKeys.TEEN_TO_ADULT,
        VictimInfoAgeCategoryVariantKeys.MINOR_TO_ADULT,
      ],
    };

    if (isEmpty(ageCategoryValue)) {
      return refineAgeCategoryOptions;
    }

    const fields = new Set(
      ageCategoryValue.flatMap(
        (parentAgeCategory) => ageCategoriesByParent[parentAgeCategory],
      ),
    );

    return refineAgeCategoryOptions.filter((ageCategory) =>
      fields.has(ageCategory.id),
    );
  };

  const filteredRefineAgeCategoryOptions = filterRefineAgeCategoryOptions(
    parentAgeCategoryValue,
  );

  const isAnyRefineFieldDefined = () => {
    const refineFieldsValues = getValues([
      VictimInfoKeys.AGE_AT_START_OF_ABUSE,
      VictimInfoKeys.AGE_AT_END_OF_ABUSE,
      AdditionalFilterParameter.REFINE_AGE_CATEGORY,
      AbuseDetailsKeys.NUMBER_OF_INCIDENTS_LOW,
      AbuseDetailsKeys.NUMBER_OF_INCIDENTS_HIGH,
      StaticPropertyKey.ORDER,
      AbuseDetailsKeys.NUMBER_OF_PERPETRATORS_LOW,
      AbuseDetailsKeys.NUMBER_OF_PERPETRATORS_HIGH,
      StaticPropertyKey.LIABILITY_DEFENSES,
      StaticPropertyKey.EXCEPTION,
      LegalDetailsKeys.PREPARATOR,
      LegalDetailsKeys.LAWSUIT_FILED,
      LegalDetailsKeys.HAS_ATTORNEY,
      StaticPropertyKey.RESOLUTION_TYPE,
      AdditionalFilterParameter.AMOUNT_LOW,
      AdditionalFilterParameter.RESOLUTION_PUBLICITY,
      EIncludeExcludeKeys.CLASS_ACTION_NAME,
      EIncludeExcludeKeys.BANKRUPTCY_NAME,
      EIncludeExcludeKeys.COMPENSATION_REDRESS_NAME,
    ]);

    return refineFieldsValues.some((value) => !isEmpty(value));
  };

  const shouldBeExpanded = isAnyRefineFieldDefined();

  const commonFields = (
    <>
      <Typography variant="h4" sx={styles.subtitle}>
        {t('advancedSearch.claimDetailsTitle')}
      </Typography>
      <Grid container item xs={12} columnSpacing={3} my={4}>
        <Grid item xs={12}>
          <Div style={{ position: 'relative' }}>
            <InputLabel variant="filled" sx={{ position: 'absolute' }}>
              {t('ageRangeOfAbuse.label')}
            </InputLabel>
          </Div>
        </Grid>
        <Grid item xs={6}>
          <TextField
            {...register(VictimInfoKeys.AGE_AT_START_OF_ABUSE, {
              setValueAs: setValueAsNumber,
            })}
            variant="filled"
            fullWidth
            type="number"
            inputProps={{ min: 0 }}
            placeholder={t(
              `${VictimInfoKeys.AGE_AT_START_OF_ABUSE}.placeholder`,
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            {...register(VictimInfoKeys.AGE_AT_END_OF_ABUSE, {
              setValueAs: setValueAsNumber,
            })}
            variant="filled"
            fullWidth
            type="number"
            inputProps={{ min: 0 }}
            placeholder={t(`${VictimInfoKeys.AGE_AT_END_OF_ABUSE}.placeholder`)}
          />
        </Grid>
      </Grid>

      <Grid item xs={12} my={4}>
        <Select2
          name={AdditionalFilterParameter.REFINE_AGE_CATEGORY}
          variant="filled"
          multiple
          items={filteredRefineAgeCategoryOptions}
          fullWidth
          placeholder={t(
            `${AdditionalFilterParameter.REFINE_AGE_CATEGORY}.placeholder`,
          )}
          label={t(`${AdditionalFilterParameter.REFINE_AGE_CATEGORY}.label`)}
        />
      </Grid>

      <Grid container item xs={12} columnSpacing={3} my={4}>
        <Grid item xs={12}>
          <Div style={{ position: 'relative' }}>
            <InputLabel variant="filled" sx={{ position: 'absolute' }}>
              {t('numberOfIncidents.label')}
            </InputLabel>
          </Div>
        </Grid>
        <Grid item xs={6}>
          <TextField
            {...register(AbuseDetailsKeys.NUMBER_OF_INCIDENTS_LOW, {
              setValueAs: setValueAsNumber,
            })}
            variant="filled"
            fullWidth
            type="number"
            inputProps={{ min: 0 }}
            placeholder={t(
              `${AbuseDetailsKeys.NUMBER_OF_INCIDENTS_LOW}.placeholder`,
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            {...register(AbuseDetailsKeys.NUMBER_OF_INCIDENTS_HIGH, {
              setValueAs: setValueAsNumber,
            })}
            variant="filled"
            fullWidth
            type="number"
            inputProps={{ min: 0 }}
            placeholder={t(
              `${AbuseDetailsKeys.NUMBER_OF_INCIDENTS_HIGH}.placeholder`,
            )}
          />
        </Grid>
      </Grid>

      <Grid item xs={12} my={4}>
        <Autocomplete
          name={StaticPropertyKey.ORDER}
          onOptionsLoad={async (data) =>
            await onOptionsLoad(StaticPropertyKey.ORDER, data)
          }
          variant="filled"
          multiple
          isPrefetching={isFormLoading}
          label={t(`${StaticPropertyKey.ORDER}.label`)}
          placeholder={t(`${StaticPropertyKey.ORDER}.placeholder`)}
        />
      </Grid>

      <Grid container item xs={12} columnSpacing={3} my={4}>
        <Grid item xs={12}>
          <Div style={{ position: 'relative' }}>
            <InputLabel variant="filled" sx={{ position: 'absolute' }}>
              {t('numberOfPerpetrators.label')}
            </InputLabel>
          </Div>
        </Grid>
        <Grid item xs={6}>
          <TextField
            {...register(AbuseDetailsKeys.NUMBER_OF_PERPETRATORS_LOW, {
              setValueAs: setValueAsNumber,
            })}
            variant="filled"
            fullWidth
            type="number"
            inputProps={{ min: 0 }}
            placeholder={t(
              `${AbuseDetailsKeys.NUMBER_OF_PERPETRATORS_LOW}.placeholder`,
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            {...register(AbuseDetailsKeys.NUMBER_OF_PERPETRATORS_HIGH, {
              setValueAs: setValueAsNumber,
            })}
            variant="filled"
            fullWidth
            type="number"
            inputProps={{ min: 0 }}
            placeholder={t(
              `${AbuseDetailsKeys.NUMBER_OF_PERPETRATORS_HIGH}.placeholder`,
            )}
          />
        </Grid>
      </Grid>

      <Typography variant="h4" sx={styles.subtitle}>
        {t('legalDetails.title')}
      </Typography>

      <Grid item xs={12} my={4}>
        <Autocomplete
          name={StaticPropertyKey.LIABILITY_DEFENSES}
          onOptionsLoad={async (data) =>
            await onOptionsLoad(StaticPropertyKey.LIABILITY_DEFENSES, data)
          }
          variant="filled"
          multiple
          isPrefetching={isFormLoading}
          label={t(`${StaticPropertyKey.LIABILITY_DEFENSES}.label`)}
          placeholder={t(`${StaticPropertyKey.LIABILITY_DEFENSES}.placeholder`)}
        />
      </Grid>

      <Grid item xs={12} my={4}>
        <Autocomplete
          name={StaticPropertyKey.EXCEPTION}
          onOptionsLoad={async (data) =>
            await onOptionsLoad(StaticPropertyKey.EXCEPTION, data)
          }
          variant="filled"
          multiple
          isPrefetching={isFormLoading}
          placeholder={t(`${StaticPropertyKey.EXCEPTION}.placeholder`)}
          label={t(`${StaticPropertyKey.EXCEPTION}.label`)}
        />
      </Grid>
    </>
  );

  const nonProfileSpecificFields = (
    <>
      <Typography variant="h4" sx={styles.subtitle}>
        {t('resolutionDetails.title')}
      </Typography>

      <Grid item xs={12} my={4}>
        <Autocomplete
          name={StaticPropertyKey.RESOLUTION_TYPE}
          onOptionsLoad={async (data) =>
            await onOptionsLoad(StaticPropertyKey.RESOLUTION_TYPE, data)
          }
          variant="filled"
          multiple
          isPrefetching={isFormLoading}
          label={t(`${StaticPropertyKey.RESOLUTION_TYPE}.label`)}
          placeholder={t(`${StaticPropertyKey.RESOLUTION_TYPE}.placeholder`)}
        />
      </Grid>

      <Grid container item xs={12} columnSpacing={3} my={2}>
        <Grid item xs={12}>
          <Div style={{ position: 'relative' }}>
            <InputLabel variant="filled" sx={{ position: 'absolute' }}>
              {t('settlementRange.label')}
            </InputLabel>
          </Div>
        </Grid>
        <Grid item xs={6}>
          <Select
            field={AdditionalFilterParameter.AMOUNT_LOW}
            items={SETTLEMENT_AMOUNT_ITEMS}
            placeholder={t(
              `${AdditionalFilterParameter.AMOUNT_LOW}.placeholder`,
            )}
            variant="filled"
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <Select
            field={AdditionalFilterParameter.AMOUNT_HIGH}
            items={SETTLEMENT_AMOUNT_ITEMS}
            placeholder={t(
              `${AdditionalFilterParameter.AMOUNT_HIGH}.placeholder`,
            )}
            variant="filled"
            fullWidth
          />
        </Grid>
      </Grid>

      {allowToSearchConfidential && (
        <Grid item xs={12} my={2}>
          <Select2
            name={AdditionalFilterParameter.RESOLUTION_PUBLICITY}
            variant="filled"
            multiple
            items={publicityVariants}
            fullWidth
            placeholder={t(
              `${AdditionalFilterParameter.RESOLUTION_PUBLICITY}.placeholder`,
            )}
            label={t(`${AdditionalFilterParameter.RESOLUTION_PUBLICITY}.label`)}
          />
        </Grid>
      )}
    </>
  );

  const profileSpecificFields = (
    <>
      <Grid item xs={12} my={4}>
        <FormControl fullWidth>
          <InputLabel>{t(`${LegalDetailsKeys.PREPARATOR}.label`)}</InputLabel>
          <Controller
            name={LegalDetailsKeys.PREPARATOR}
            control={control}
            render={({ field }) => (
              <RadioGroup {...field} row style={{ marginTop: 8 }}>
                <FormControlLabel
                  value={YesNoVariantsKey.YES}
                  control={<Radio />}
                  label="Yes"
                />
                <FormControlLabel
                  value={YesNoVariantsKey.NO}
                  control={<Radio />}
                  label="No"
                />
              </RadioGroup>
            )}
          />
        </FormControl>
      </Grid>

      <Grid item xs={12} my={4}>
        <FormControl fullWidth>
          <InputLabel>
            {t(`${LegalDetailsKeys.LAWSUIT_FILED}.label`)}
          </InputLabel>
          <Controller
            name={LegalDetailsKeys.LAWSUIT_FILED}
            control={control}
            render={({ field }) => (
              <RadioGroup {...field} row style={{ marginTop: 8 }}>
                <FormControlLabel
                  value={YesNoVariantsKey.YES}
                  control={<Radio />}
                  label="Yes"
                />
                <FormControlLabel
                  value={YesNoVariantsKey.NO}
                  control={<Radio />}
                  label="No"
                />
              </RadioGroup>
            )}
          />
        </FormControl>
      </Grid>

      <Grid item xs={12} my={4}>
        <FormControl fullWidth>
          <InputLabel>{t(`${LegalDetailsKeys.HAS_ATTORNEY}.label`)}</InputLabel>
          <Controller
            {...register(LegalDetailsKeys.HAS_ATTORNEY)}
            control={control}
            render={({ field }) => (
              <RadioGroup {...field} row style={{ marginTop: 8 }}>
                <FormControlLabel
                  value={YesNoVariantsKey.YES}
                  control={<Radio />}
                  label="Yes"
                />
                <FormControlLabel
                  value={YesNoVariantsKey.NO}
                  control={<Radio />}
                  label="No"
                />
              </RadioGroup>
            )}
          />
        </FormControl>
      </Grid>
    </>
  );

  return (
    <Accordion defaultExpanded={shouldBeExpanded}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h4" my={1}>
          {t('advancedSearch.title')}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        {commonFields}
        {profileExists ? null : profileSpecificFields}
        {nonProfileSpecificFields}
      </AccordionDetails>
    </Accordion>
  );
};
