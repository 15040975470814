import dayjs from 'dayjs';
import { TFunction } from 'i18next';
import get from 'lodash/get';

import {
  AbuseDetailsKeys,
  EIncludeExcludeKeys,
  LegalDetailsKeys,
  LegalDetailsPreparatorVariantsKey,
  StaticPropertyKey,
  VictimInfoKeys,
  YesNoVariantsKey,
} from '@bvi/api-interfaces/entity/case-property';
import { AdditionalFilterParameter } from '@bvi/api-interfaces/request/case';
import { ISettingsData } from '@bvi/api-interfaces/response/case-settings';
import { DateFormat } from '@bvi/date-utils';

import {
  ISearchFormDataSchema,
  ISearchQuery,
  searchFormPropertySchemas,
} from '.';

export const getTagListByFormData = (
  searchParameters: ISearchQuery,
  formData: ISettingsData,
  t: TFunction,
) => {
  const keys = Object.keys(searchParameters).filter(
    (key) => key in searchFormPropertySchemas,
  );

  const dictionary = {
    [StaticPropertyKey.COUNTRY]: {
      translatePath: `form.${StaticPropertyKey.COUNTRY}.label`,
      value: () =>
        formData[StaticPropertyKey.COUNTRY]?.items.map((item) => item.name),
    },
    [StaticPropertyKey.REGION]: {
      translatePath: `form.${StaticPropertyKey.REGION}.label`,
      value: () =>
        formData[StaticPropertyKey.REGION]?.items.map((item) => item.name),
    },
    [StaticPropertyKey.STATE]: {
      translatePath: `form.${StaticPropertyKey.STATE}.label`,
      value: () =>
        formData[StaticPropertyKey.STATE]?.items.map((item) => item.name),
    },
    [VictimInfoKeys.AGE_CATEGORY]: {
      translatePath: `form.${VictimInfoKeys.AGE_CATEGORY}.label`,
      value: () => {
        const ageCategory =
          searchParameters[VictimInfoKeys.AGE_CATEGORY].split(',') ?? [];
        const refineAgeCategory =
          searchParameters[
            AdditionalFilterParameter.REFINE_AGE_CATEGORY
          ]?.split(',') ?? [];

        return [...ageCategory, ...refineAgeCategory].map((value) =>
          t(`ageCategory.${value}`),
        );
      },
    },
    [AdditionalFilterParameter.REFINE_AGE_CATEGORY]: {
      translatePath: '',
      value: () => null,
    },
    [VictimInfoKeys.GENDER]: {
      translatePath: `form.${VictimInfoKeys.GENDER}.label`,
      value: () => {
        const currentValues =
          searchParameters[VictimInfoKeys.GENDER].split(',');

        return currentValues.map((value) => t(`genderValues.${value}`));
      },
    },
    [StaticPropertyKey.ORGANIZATION]: {
      translatePath: `form.${StaticPropertyKey.ORGANIZATION}.label`,
      value: () =>
        formData[StaticPropertyKey.ORGANIZATION]?.items.map(
          (item) => item.name,
        ),
    },
    [StaticPropertyKey.DIOCESE]: {
      translatePath: `form.${StaticPropertyKey.DIOCESE}.label`,
      value: () =>
        formData[StaticPropertyKey.DIOCESE]?.items.map((item) => item.name),
    },
    [StaticPropertyKey.ORGANIZATION_TYPE]: {
      translatePath: `form.${StaticPropertyKey.ORGANIZATION_TYPE}.label`,
      value: () =>
        formData[StaticPropertyKey.ORGANIZATION_TYPE]?.items.map(
          (item) => item.name,
        ),
    },
    [VictimInfoKeys.AGE_AT_START_OF_ABUSE]: {
      translatePath: `form.${VictimInfoKeys.AGE_AT_START_OF_ABUSE}.label`,
      value: () => get(searchParameters, VictimInfoKeys.AGE_AT_START_OF_ABUSE),
    },
    [VictimInfoKeys.AGE_AT_END_OF_ABUSE]: {
      translatePath: `form.${VictimInfoKeys.AGE_AT_END_OF_ABUSE}.label`,
      value: () => get(searchParameters, VictimInfoKeys.AGE_AT_END_OF_ABUSE),
    },
    [AdditionalFilterParameter.RESOLUTION_DATE_LOW]: {
      translatePath: `form.${AdditionalFilterParameter.RESOLUTION_DATE_LOW}.label`,
      value: () => {
        const date = get(
          searchParameters,
          AdditionalFilterParameter.RESOLUTION_DATE_LOW,
        );

        return date && dayjs(Number(date)).format(DateFormat.default);
      },
    },
    [AdditionalFilterParameter.RESOLUTION_DATE_HIGH]: {
      translatePath: `form.${AdditionalFilterParameter.RESOLUTION_DATE_HIGH}.label`,
      value: () => {
        const date = get(
          searchParameters,
          AdditionalFilterParameter.RESOLUTION_DATE_HIGH,
        );

        return date && dayjs(Number(date)).format(DateFormat.default);
      },
    },
    [AbuseDetailsKeys.NUMBER_OF_INCIDENTS_LOW]: {
      translatePath: `form.${AbuseDetailsKeys.NUMBER_OF_INCIDENTS_LOW}.label`,
      value: () =>
        get(searchParameters, AbuseDetailsKeys.NUMBER_OF_INCIDENTS_LOW),
    },
    [AbuseDetailsKeys.NUMBER_OF_INCIDENTS_HIGH]: {
      translatePath: `form.${AbuseDetailsKeys.NUMBER_OF_INCIDENTS_HIGH}.label`,
      value: () =>
        get(searchParameters, AbuseDetailsKeys.NUMBER_OF_INCIDENTS_HIGH),
    },
    [AbuseDetailsKeys.LEVEL_1]: {
      translatePath: `form.${AbuseDetailsKeys.LEVEL_1}.label`,
      value: () => {
        const currentValues =
          searchParameters[AbuseDetailsKeys.LEVEL_1].split(',');

        return currentValues.map((value) => t(`form.abuseTypeItems.${value}`));
      },
    },
    [AbuseDetailsKeys.LEVEL_2]: {
      translatePath: `form.${AbuseDetailsKeys.LEVEL_2}.label`,
      value: () => {
        const currentValues =
          searchParameters[AbuseDetailsKeys.LEVEL_2].split(',');

        return currentValues.map((value) => t(`form.abuseTypeItems.${value}`));
      },
    },
    [AbuseDetailsKeys.LEVEL_3]: {
      translatePath: `form.${AbuseDetailsKeys.LEVEL_3}.label`,
      value: () => {
        const currentValues =
          searchParameters[AbuseDetailsKeys.LEVEL_3].split(',');

        return currentValues.map((value) => t(`form.abuseTypeItems.${value}`));
      },
    },
    [AbuseDetailsKeys.LEVEL_4]: {
      translatePath: `form.${AbuseDetailsKeys.LEVEL_4}.label`,
      value: () => {
        const currentValues =
          searchParameters[AbuseDetailsKeys.LEVEL_4].split(',');

        return currentValues.map((value) => t(`form.abuseTypeItems.${value}`));
      },
    },
    [AbuseDetailsKeys.LEVEL_5]: {
      translatePath: `form.${AbuseDetailsKeys.LEVEL_5}.label`,
      value: () => {
        const currentValues =
          searchParameters[AbuseDetailsKeys.LEVEL_5].split(',');

        return currentValues.map((value) => t(`form.abuseTypeItems.${value}`));
      },
    },
    [StaticPropertyKey.JOB_TITLE]: {
      translatePath: `form.${StaticPropertyKey.JOB_TITLE}.label`,
      value: () =>
        formData[StaticPropertyKey.JOB_TITLE]?.items.map((item) => item.name),
    },
    [StaticPropertyKey.ORDER]: {
      translatePath: `form.${StaticPropertyKey.ORDER}.label`,
      value: () =>
        formData[StaticPropertyKey.ORDER]?.items.map((item) => item.name),
    },
    [AbuseDetailsKeys.NUMBER_OF_PERPETRATORS_LOW]: {
      translatePath: `form.${AbuseDetailsKeys.NUMBER_OF_PERPETRATORS_LOW}.label`,
      value: () =>
        get(searchParameters, AbuseDetailsKeys.NUMBER_OF_PERPETRATORS_LOW),
    },
    [AbuseDetailsKeys.NUMBER_OF_PERPETRATORS_HIGH]: {
      translatePath: `form.${AbuseDetailsKeys.NUMBER_OF_PERPETRATORS_HIGH}.label`,
      value: () =>
        get(searchParameters, AbuseDetailsKeys.NUMBER_OF_PERPETRATORS_HIGH),
    },
    [StaticPropertyKey.LIABILITY_DEFENSES]: {
      translatePath: `form.${StaticPropertyKey.LIABILITY_DEFENSES}.label`,
      value: () =>
        formData[StaticPropertyKey.LIABILITY_DEFENSES]?.items.map(
          (item) => item.name,
        ),
    },
    [StaticPropertyKey.EXCEPTION]: {
      translatePath: `form.${StaticPropertyKey.EXCEPTION}.label`,
      value: () =>
        formData[StaticPropertyKey.EXCEPTION]?.items.map((item) => item.name),
    },
    [LegalDetailsKeys.PREPARATOR]: {
      translatePath: `form.${LegalDetailsKeys.PREPARATOR}.label`,
      value: () => {
        const preparatorValue = searchParameters[
          LegalDetailsKeys.PREPARATOR
        ] as LegalDetailsPreparatorVariantsKey;
        const deniedAbuse = [
          LegalDetailsPreparatorVariantsKey.DENIED_ABUSE,
        ].includes(preparatorValue)
          ? YesNoVariantsKey.YES
          : YesNoVariantsKey.NO;

        return t(`agreementValues.${deniedAbuse}`);
      },
    },
    [EIncludeExcludeKeys.CLASS_ACTION_NAME]: {
      translatePath: `form.${EIncludeExcludeKeys.CLASS_ACTION_NAME}.label`,
      value: () => {
        const value = searchParameters[
          EIncludeExcludeKeys.CLASS_ACTION_NAME
        ] as EIncludeExcludeKeys;
        const filter = [EIncludeExcludeKeys.CLASS_ACTION_NAME].includes(value)
          ? 'Include'
          : 'Exclude';
        return t(`${filter}`);
      },
    },
    [EIncludeExcludeKeys.BANKRUPTCY_NAME]: {
      translatePath: `form.${EIncludeExcludeKeys.BANKRUPTCY_NAME}.label`,
      value: () => {
        const value = searchParameters[
          EIncludeExcludeKeys.BANKRUPTCY_NAME
        ] as EIncludeExcludeKeys;
        const filter = [EIncludeExcludeKeys.BANKRUPTCY_NAME].includes(value)
          ? 'Include'
          : 'Exclude';
        return t(`${filter}`);
      },
    },
    [EIncludeExcludeKeys.COMPENSATION_REDRESS_NAME]: {
      translatePath: `form.${EIncludeExcludeKeys.COMPENSATION_REDRESS_NAME}.label`,
      value: () => {
        const value = searchParameters[
          EIncludeExcludeKeys.COMPENSATION_REDRESS_NAME
        ] as EIncludeExcludeKeys;
        const filter = [EIncludeExcludeKeys.COMPENSATION_REDRESS_NAME].includes(
          value,
        )
          ? 'Include'
          : 'Exclude';
        return t(`${filter}`);
      },
    },
    [StaticPropertyKey.RESOLUTION_TYPE]: {
      translatePath: `form.${StaticPropertyKey.RESOLUTION_TYPE}.label`,
      value: () =>
        formData[StaticPropertyKey.RESOLUTION_TYPE]?.items.map(
          (item) => item.name,
        ),
    },
    [LegalDetailsKeys.LAWSUIT_FILED]: {
      translatePath: `form.${LegalDetailsKeys.LAWSUIT_FILED}.label`,
      value: () =>
        t(
          `agreementValues.${searchParameters[LegalDetailsKeys.LAWSUIT_FILED]}`,
        ),
    },
    [LegalDetailsKeys.HAS_ATTORNEY]: {
      translatePath: `form.${LegalDetailsKeys.HAS_ATTORNEY}.label`,
      value: () =>
        t(`agreementValues.${searchParameters[LegalDetailsKeys.HAS_ATTORNEY]}`),
    },
    [AdditionalFilterParameter.AMOUNT_LOW]: {
      translatePath: `form.${AdditionalFilterParameter.AMOUNT_LOW}.label`,
      value: () =>
        formatCurrency(searchParameters[AdditionalFilterParameter.AMOUNT_LOW]),
    },
    [AdditionalFilterParameter.AMOUNT_HIGH]: {
      translatePath: `form.${AdditionalFilterParameter.AMOUNT_HIGH}.label`,
      value: () =>
        formatCurrency(searchParameters[AdditionalFilterParameter.AMOUNT_HIGH]),
    },
    [AdditionalFilterParameter.RESOLUTION_PUBLICITY]: {
      translatePath: `form.${AdditionalFilterParameter.RESOLUTION_PUBLICITY}.label`,
      value: () => {
        const currentValues =
          searchParameters[
            AdditionalFilterParameter.RESOLUTION_PUBLICITY
          ].split(',');

        return currentValues.map((value) =>
          t(`${AdditionalFilterParameter.RESOLUTION_PUBLICITY}.${value}`),
        );
      },
    },
  };

  return keys.map((tag) => {
    const tagItem = dictionary[tag as keyof ISearchFormDataSchema];

    return {
      key: tag,
      label: t(tagItem.translatePath),
      value: tagItem.value(),
    };
  });
};

export const formatCurrency = (value: string | number) => {
  return new Intl.NumberFormat('en-EN', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0,
  }).format(Number(value));
};

export const formatResolutionRangeValue = (value: string | number) => {
  return new Intl.NumberFormat('en-EN', {
    style: 'currency',
    currency: 'USD',
    compactDisplay: 'short',
    notation: 'compact',
  }).format(Number(value));
};

export const formatNumber = (value: string | number) => {
  if (Number.isNaN(Number(value))) {
    return '—';
  }

  return new Intl.NumberFormat('en-EN').format(Number(value));
};
