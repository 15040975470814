import { PropsWithChildren } from 'react';

import { Div } from '../Div';

import { styles } from './styles';

type DataPopoverProperties = PropsWithChildren<{
  title?: React.ReactNode;
  style?: React.CSSProperties;
}>;

export const DataPopover: React.FC<DataPopoverProperties> = (properties) => {
  const { title, children, style, ...restProperties } = properties;

  return (
    <Div sx={styles.block} {...restProperties}>
      {title && (
        <Div sx={styles.header} style={{ ...style }}>
          {title}
        </Div>
      )}
      <Div sx={styles.wrapper}>{children}</Div>
    </Div>
  );
};
