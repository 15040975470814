import { SxProperties } from '@bvi/mui-theme';

export const styles = {
  wrapper: {
    paddingLeft: 3,
    paddingRight: 3,
  },
  buttonsWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  caseHadingBoxWrapper: {
    backgroundColor: '#fdebc3',
    padding: '0px 10px',
    borderRadius: '5px',
    boxShadow: '1px 3px 4px rgba(0, 0, 0, 0.15)',
  },
} satisfies SxProperties;
