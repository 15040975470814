import { IconButton, Tooltip, TooltipProps } from '@mui/material';

import { Image } from '../Image';
import { help as HelpIcon } from '../icons';

import { styles } from './styles';

type HelpTooltipProperties = Omit<TooltipProps, 'children'>;

export const HelpTooltip: React.FC<HelpTooltipProperties> = (properties) => {
  return (
    <Tooltip {...properties}>
      <IconButton size="small">
        <Image src={HelpIcon} sx={styles.icon} />
      </IconButton>
    </Tooltip>
  );
};
