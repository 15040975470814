import { useSelector } from 'react-redux';

import { ICaseStatisticsData } from '@bvi/api-interfaces/response/case';
import {
  IGetSettingsQuery,
  ISearchFormDataSchema,
  mapFormDataToSubmit,
  SearchContext,
  SearchFilters,
  SearchLayout,
} from '@bvi/cases-search';
import { useLazyGetSettingsQuery } from '@bvi/dashboard/entities/search/api-slice';
import { selectCurrentUser } from '@bvi/dashboard/entities/user-auth/selectors';
import { SearchResult } from '@bvi/dashboard/feature/search/ui';
import { DashboardRoutesPaths } from '@bvi/dashboard/shared/constants/routes';
import { useNavigation } from '@bvi/navigation-utils';

const Filters = ({
  statisticsData,
  isFetching,
}: {
  statisticsData: ICaseStatisticsData;
  isFetching: boolean;
}) => {
  const [getSettings] = useLazyGetSettingsQuery();
  const currentUser = useSelector(selectCurrentUser());
  const profileExists = 'profile' in currentUser;

  const handleLoadSettings = async (data: IGetSettingsQuery) => {
    const result = await getSettings(data);

    return result.data?.success ? result.data.payload.data : {};
  };

  return (
    <SearchFilters
      onLoadSettings={handleLoadSettings}
      profileExists={profileExists}
      statisticsData={statisticsData}
      isFetching={isFetching}
    />
  );
};

export const Search: React.FC<{
  statisticsData: ICaseStatisticsData;
  isFetching: boolean;
  includeStatisticsData?: ICaseStatisticsData;
}> = ({ statisticsData, isFetching, includeStatisticsData }) => {
  const navigate = useNavigation();

  const handleSubmit = (data: ISearchFormDataSchema) => {
    const preparedData = mapFormDataToSubmit(data);

    navigate(DashboardRoutesPaths.SEARCH, preparedData);
  };

  return (
    <SearchContext.Provider
      value={{
        onSubmit: handleSubmit,
        includeStatisticsData: includeStatisticsData,
        route: DashboardRoutesPaths.SEARCH,
      }}
    >
      <SearchLayout
        filters={
          <Filters statisticsData={statisticsData} isFetching={isFetching} />
        }
        results={<SearchResult />}
      />
    </SearchContext.Provider>
  );
};
