import { IBaseProperties } from './base';

export enum CasePropertyType {
  VARIANTS = 'variants',
  MULTI_VARIANTS = 'multi-variants',
  /** @doc for long text arias */
  TEXT = 'text',
  /** @doc for short combo-box */
  STRING = 'string',
  NUMERIC = 'numeric',
  CHECKBOX = 'checkbox',
  RADIO = 'radio',
  DATE = 'date',
  RELATION = 'relation',
}

export interface ICaseProperty extends IBaseProperties {
  key: string;
  type: CasePropertyType;
  variants?: Array<ICasePropertyVariant>;
  meta?: ICasePropertiesMeta;
}

export interface ICasePropertyValue<T = unknown> {
  value: T;
  meta?: Record<string, unknown>;
}

export interface ICaseProperties {
  [key: string]: ICasePropertyValue;
}

export interface ICasePropertyVariant {
  key: string;
  label?: IMetaText;
}

interface IMetaText {
  en: string;
}

export interface ICasePropertiesMeta {
  /** @doc This title will be used as title of category/property on the create/edit form */
  formTitle?: IMetaText;
  /** @doc This title will be used in the filters */
  filterTitle?: IMetaText;
  /** @doc This description will ne shown in the tooltips */
  description?: IMetaText;
  relation?: {
    /** @doc This is the entity name of the relation */
    entity: string;
    /** @doc This is the property name of the relation */
    property: string;
  };
  [key: string]: unknown;
}

export enum CasePropertyCategoryKey {
  VICTIM_INFO = 'victim-info',
  PERPETRATOR_INFO = 'perpetrator-info',
  ABUSE_DETAILS = 'abuse-details',
  LEGAL_DETAILS = 'legal-details',
}

export enum VictimInfoKeys {
  GENDER = 'gender',
  BIRTH_DATE = 'birth-date',
  AGE_CATEGORY = 'age-category',
  STARTED_ABUSE_AT = 'started-abuse-at',
  AGE_AT_START_OF_ABUSE = 'age-at-start-of-abuse',
  AGE_AT_END_OF_ABUSE = 'age-at-end-of-abuse',
  END_OF_ABUSE_AT = 'end-of-abuse-at',
}

export enum VictimInfoGenderVariantKeys {
  MALE = 'male',
  FEMALE = 'female',
  UNKNOWN = 'unknown',
  ALL = 'all',
}

export enum VictimInfoAgeCategoryVariantKeys {
  PREPUBESCENT = 'prepubescent',
  MINOR = 'minor',
  TEEN = 'teen',
  ADULT = 'adult',
  PREPUBESCENT_TO_TEEN = 'prepubescent-to-teen',
  PREPUBESCENT_TO_ADULT = 'prepubescent-to-adult',
  MINOR_TO_ADULT = 'minor-to-adult',
  TEEN_TO_ADULT = 'teen-to-adult',
}

export enum PerpetratorInfoKeys {
  NAME = 'name',
  JOB = 'job',
  RELIGION_ORDER = 'religion-order',
  SUBSIDIARY = 'subsidiary',
}

export enum PerpetratorInfoJobVariantKeys {
  PRIEST = 'priest',
  TEACHER = 'teacher',
  COACH = 'coach',
  FAMILY_MEMBER = 'family-member',
  MINISTER = 'minister',
  MAINTENANCE = 'maintenance',
  STAFF = 'staff',
  ELDER = 'elder',
  MONK = 'monk',
  SISTER_NUN = 'sister-nun',
  OTHER = 'other',
}

export enum PerpetratorInfoReligionOrder {
  JESUITS = 'jesuits',
  HOLY_CROSS = 'holy-cross',
  BENEDICTINES = 'benedictines',
  DOMINICANS = 'dominicans',
}

export enum CaseInformation {
  CLAIMANT_TYPE = 'claimant-type',
  CLAIMANT_COUNT = 'claimant-count',
  CLAIMANT_NAME = 'claimant-name',
}

export enum ClaimantTypeVariantKeys {
  SINGLE = 'single',
  GROUP = 'group',
}

export enum PerpetratorInfoOrganizationVariantKeys {
  ROMAN_CATHOLIC_CHURCH = 'roman-catholic-church',
  BAPTIST_CHURCH = 'baptist-church',
  BOY_SCOUTS_OF_AMERICA = 'boy-scouts-of-america',
  EPISCOPAL_CHURCH = 'episcopal-church',
}

export enum PerpetratorInfoSubsidiaryVariantKeys {
  ARCHDIOCESE_OF_NEW_YORK = 'archdiocese-of-new-york',
  DIOCESE_OF_ALBANY = 'diocese-of-albany',
  DIOCESE_OF_BUFFALO = 'diocese-of-buffalo',
  DIOCESE_OF_OGDENSBURG = 'diocese-of-ogdensburg',
  DIOCESE_OF_ROCHESTER = 'diocese-of-rochester',
  DIOCESE_OF_ROCKVILLE_CENTRE = 'diocese-of-rockville-centre',
  DIOCESE_OF_SYRACUSE = 'diocese-of-syracuse',
}

export enum AbuseDetailsKeys {
  DETAIL_AVAILABLE = 'detail-available',
  LEVEL_1 = 'level-1',
  LEVEL_2 = 'level-2',
  LEVEL_3 = 'level-3',
  LEVEL_4 = 'level-4',
  LEVEL_5 = 'level-5',
  LEVEL_1_UNSPECIFIED = 'level-1-unspecified',
  LEVEL_2_UNSPECIFIED = 'level-2-unspecified',
  LEVEL_3_UNSPECIFIED = 'level-3-unspecified',
  LEVEL_4_UNSPECIFIED = 'level-4-unspecified',
  LEVEL_5_UNSPECIFIED = 'level-5-unspecified',
  OTHER = 'other',
  NUMBER_OF_INCIDENTS_LOW = 'number-of-incidents-low',
  NUMBER_OF_INCIDENTS_HIGH = 'number-of-incidents-high',
  NUMBER_OF_PERPETRATORS_LOW = 'number-of-perpetrators-low',
  NUMBER_OF_PERPETRATORS_HIGH = 'number-of-perpetrators-high',
}

export enum AbuseDetailsLevel1VariantKeys {
  SUGGESTIVE_SEXUAL_TALK = 'suggestive-sexual-talk',
  KISSING_ANG_HUGGING = 'kissing-ang-hugging',
  TOUCHING_NON_PRIVATE_PARTS = 'touching-non-private-parts',
  PROVIDING_VIEWING_PORNOGRAPHY = 'providing-viewing-pornography',
  EXPOSURE_OF_PERPETRATOR_TO_GENITALS = 'exposure-of-perpetrator-genitals',
  BUTTOCKS_FONDING = 'buttocks-fonding',
  UNSPECIFIED = 'unspecified',
}

export enum AbuseDetailsLevel2VariantKeys {
  WITNESS_PERPETRATOR_MASTURBATE = 'witness-perpetrator-masturbate',
  PROVIDED_ALCOHOL_DRUGS = 'provided-alcohol-drugs',
  FRENCH_KISSING = 'french-kissing',
  BREAST_FONDLING = 'breast-fondling',
  PRESSING_GENITALS_AGAINST_CLAIMANT = 'pressing-genitals-against-claimant',
  CLAIMANT_IS_UNDRESSED_NAKED = 'claimant-is-undressed-naked',
  OVER_THE_CLOTHES_GENITAL_FONDLING = 'over-the-clothes-genital-fondling',
  UNSPECIFIED = 'unspecified',
}

export enum AbuseDetailsLevel3VariantKeys {
  MUTUAL_SELF_MASTURBATION = 'mutual-self-masturbation',
  MUTUAL_MASTURBATION_OF_EACH_OTHER = 'mutual-masturbation-of-each-other',
  SEX_TOYS_PARAPHERNALIA = 'sex-toys-paraphernalia',
  COERCED_MASTURBATION_OF_PERPETRATOR = 'coerced-masturbation-of-perpetrator',
  SKIN_TO_SKIN_GENITAL_FONDLING = 'skin-to-skin-genital-fondling',
  UNSPECIFIED = 'unspecified',
}

export enum AbuseDetailsLevel4VariantKeys {
  ORAL_SEX_UPON_CLAIMANT = 'oral-sex-upon-claimant',
  ATTEMPTED_SODOMY = 'attempted-sodomy',
  ANAL_DIGITAL_PENETRATION = 'anal-digital-penetration',
  WEAPON_INTIMIDATION = 'weapon-intimidation',
  VAGINAL_DIGITAL_PENETRATION = 'vaginal-digital-penetration',
  ORAL_ANAL_STIMULATION_OF_CLAIMANT = 'oral-anal-stimulation-of-claimant',
  COERCED_ORAL_SEX_UPON_PERPETRATOR = 'coerced-oral-sex-upon-perpetrator',
  UNSPECIFIED = 'unspecified',
}

export enum AbuseDetailsLevel5VariantKeys {
  ANAL_SODOMY = 'anal-sodomy',
  RAPE = 'rape',
  VAGINAL_INTERCOURSE = 'vaginal-intercourse',
  SEX_TRAFFICKING = 'sex-trafficking',
  COERCED_ORAL_ANAL_STIMULATION_OF_PERPETRATOR = 'coerced-oral-anal-stimulation-of-perpetrator',
  CREATION_OF_CHILD_PORNOGRAPHY_USING_CLAIMANT = 'creation-of-child-pornography-using-claimant',
  UNSPECIFIED = 'unspecified',
}

export enum EIncludeExcludeValue {
  YES = '1',
  NO = '0',
}

export enum EIncludeExcludeName {
  EXCLUDE = 'Exclud',
  INCLUDE = 'Includ',
}

export enum EIncludeExcludeKeys {
  BANKRUPTCY_NAME = 'bankruptcy',
  COMPENSATION_REDRESS_NAME = 'compensationFund',
  CLASS_ACTION_NAME = 'classAction',
}

export enum LegalDetailsKeys {
  OTHER_LIFE_STRESS = 'other-life-stress',
  LAWSUIT_FILED = 'lawsuit-filed',
  HAS_ATTORNEY = 'has-attorney',
  LIABILITY_DEFENSE = 'liability-defense',
  PREPARATOR = 'preparator',
  DAMAGES_COMMENT = 'damages-comment',
  NOTES = 'notes',
}

export enum LegalDetailsLiabilityDefenseVariantsKey {
  STATUTE_OF_LIMITATIONS = 'statute-of-limitations',
  STATUTE_OF_REPOSE = 'statute-of-repose',
  CHARITABLE_IMMUNITY = 'charitable-immunity',
  CONSENSUAL = 'consensual',
  ADULT_RELATION_SHIP = 'adult-relation-ship',
  FALSE_ACCUSATION = 'false-accusation',
  FIRST_AMENDMENT = 'first-amendment',
  UNFORESEEN_THIRD_PARTY_ATTACK = 'unforeseen-third-party-attack',
  NONE = 'none',
}

export enum LegalDetailsExceptionsVariantsKey {
  NY_CHILD_VICTIM_ACT = 'ny-child-victim-act',
  NJ_STATUTE_OF_LIMITATIONS_WINDOW = 'nj-statute-of-limitations-window',
}

export enum LegalDetailsPreparatorVariantsKey {
  ADMITTED_ABUSE = 'admitted-abuse',
  OTHERWISE_DEEMED_GUILTY = 'otherwise-deemed-guilty',
  DENIED_ABUSE = 'denied-abuse',
  DECEASED_WHEN_ACCUSED = 'deceased-when-accused',
  CONVICTED = 'convicted',
}

export enum YesNoVariantsKey {
  YES = 'yes',
  NO = 'no',
}

export enum StaticPropertyKey {
  COUNTRY = 'country',
  REGION = 'region',
  STATE = 'state',
  ORDER = 'order',
  ORGANIZATION = 'organization',
  ORGANIZATION_TYPE = 'organizationType',
  DIOCESE = 'diocese',
  JOB_TITLE = 'jobTitle',
  RESOLUTION_TYPE = 'resolutionType',
  RESOLUTION_CURRENCY = 'resolutionCurrency',
  LIABILITY_DEFENSES = 'liabilityDefenses',
  EXCEPTION = 'exception',
  DAMAGE = 'damage',
  GROUP = 'group',
}

export type DynamicPropertyKey =
  | VictimInfoKeys
  | PerpetratorInfoKeys
  | AbuseDetailsKeys
  | LegalDetailsKeys
  | EIncludeExcludeKeys;

export type PropertyKey = StaticPropertyKey | DynamicPropertyKey;

const STATIC_PROPERTY_KEYS = new Set(Object.values<string>(StaticPropertyKey));

const DYNAMIC_PROPERTY_KEYS = new Set(
  [
    VictimInfoKeys,
    PerpetratorInfoKeys,
    AbuseDetailsKeys,
    LegalDetailsKeys,
  ].flatMap<string>((_enum) => Object.values(_enum)),
);

export enum BaseCaseEntityProperties {
  DESCRIPTION = 'description',
  UPDATED_AT = 'updatedAt',
  CREATED_AT = 'createdAt',
}

export enum StaticResolutionProperties {
  RESOLUTION_DATE = 'resolution.resolutionDate',
  RESOLUTION_TYPE = 'resolution.type',
  RESOLUTION_TOTAL = 'resolution.total',
  RESOLUTION_PER_CLAIMANT = 'resolution.perClaimant',
  RESOLUTION_DEFENSE_COSTS = 'resolution.defenseCosts',
  RESOLUTION_CLAIMANT_COUNT = 'resolution.claimantCount',
  NOTES = 'resolution.notes',
  IS_PUBLIC = 'resolution.isPublic',
}

export enum ResolutionPublicityVariantsKey {
  PUBLIC = 'public',
  CONFIDENTIAL = 'confidential',
}

export const SORTABLE_DYNAMIC_PROPERTY_KEYS = new Set(
  [
    LegalDetailsKeys,
    VictimInfoKeys,
    AbuseDetailsKeys,
    PerpetratorInfoKeys,
  ].flatMap<string>((_enum) =>
    Object.values(_enum).map((key) => `properties.${key}`),
  ),
);

export const BASE_CASE_ENTITY_PROPERTIES = new Set(
  Object.values<string>(BaseCaseEntityProperties),
);

export const STATIC_RESOLUTION_PROPERTIES = new Set(
  Object.values<string>(StaticResolutionProperties),
);

export const SORTABLE_PROPERTY_KEYS = new Set([
  ...STATIC_PROPERTY_KEYS,
  ...SORTABLE_DYNAMIC_PROPERTY_KEYS,
  ...BASE_CASE_ENTITY_PROPERTIES,
  ...STATIC_RESOLUTION_PROPERTIES,
]);

export const isBaseCaseEntityProperty = (
  value: string,
): value is BaseCaseEntityProperties => {
  return BASE_CASE_ENTITY_PROPERTIES.has(value);
};

export const isStaticResolutionProperty = (
  value: string,
): value is StaticResolutionProperties => {
  return STATIC_RESOLUTION_PROPERTIES.has(value);
};

export const isSortableDynamicPropertyKey = (
  value: string,
): value is DynamicPropertyKey => {
  return SORTABLE_DYNAMIC_PROPERTY_KEYS.has(value);
};

export const isStaticPropertyKey = (
  value: string,
): value is StaticPropertyKey => {
  return STATIC_PROPERTY_KEYS.has(value);
};

export const isDynamicPropertyKey = (
  value: string,
): value is DynamicPropertyKey => {
  return DYNAMIC_PROPERTY_KEYS.has(value);
};

export const isPropertyKey = (value: string): value is PropertyKey => {
  return isStaticPropertyKey(value) || isDynamicPropertyKey(value);
};
