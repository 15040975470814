import { Skeleton, TableCell, TableRow } from '@mui/material';

import { Div } from '@bvi/common-components';

import { formatNumber } from '../../../lib';
import { styles } from '../styles';

type RowProperties = {
  label: string;
  total?: number;
  cells: Array<{ value: string | number | null }>;
  isLoading?: boolean;
};
const customFormatCurrency = (value: string | number) => {
  return new Intl.NumberFormat('en-EN', {
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  }).format(Number(value));
};

const formatAmount = (value: string | number | null) => {
  const isUnavailable =
    value === null ||
    value === undefined ||
    value === '' ||
    Number.isNaN(value);

  return isUnavailable ? '—' : customFormatCurrency(value);
};

export const ResultRow: React.FC<RowProperties> = (properties) => {
  const { label, cells, total, isLoading } = properties;

  return (
    <TableRow>
      <TableCell component="th" scope="row" sx={styles.headerCell}>
        <Div sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
          <Div sx={{ whiteSpace: 'nowrap' }}>{label}</Div>
          {total && (
            <Div
              sx={{
                fontSize: '15px',
                fontStyle: 'italic',
                fontWeight: 500,
                marginLeft: '16px',
              }}
            >
              Count: {formatNumber(total as number)}
            </Div>
          )}
        </Div>
      </TableCell>
      {cells.map((cell, index) => (
        <TableCell
          data-testid={`results_${label}_${index}`}
          key={`${label}_${cell.value}_${index}`}
          sx={styles.valueCell}
        >
          {isLoading ? (
            <Skeleton key={`${label}_${cell.value}_${index}`} />
          ) : (
            <Div sx={{ display: 'flex', width: '100%' }}>
              <Div sx={{ width: '20%' }}>$</Div>
              <Div sx={{ width: '80%' }}>{formatAmount(cell.value)}</Div>
            </Div>
          )}
        </TableCell>
      ))}
    </TableRow>
  );
};
