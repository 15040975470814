import {
  ChartOptions,
  LegendItem,
  ChartDataset,
  ChartData,
  Chart,
} from 'chart.js';
import {
  PartialEventContext,
  LabelPosition,
  AnnotationOptions,
} from 'chartjs-plugin-annotation';
import { TFunction } from 'i18next';
import fromPairs from 'lodash/fromPairs';
import noop from 'lodash/noop';
import toPairs from 'lodash/toPairs';
import uniqBy from 'lodash/uniqBy';

import { formatCurrency, IRequiredStatistics } from '../../lib';

export const calculateLabelPosition = (
  context: PartialEventContext,
  defaultPosition: 'start' | 'end' | 'center' = 'center',
) => {
  const chartSize = context.chart.width;
  const startPos = Number(context.element?.x) < 0 && 'start';
  const endPos = Number(context.element?.x2) > chartSize && 'end';

  return {
    x: (startPos || endPos || defaultPosition) as LabelPosition,
  };
};

const getDataValues = (data: IRequiredStatistics) => {
  const {
    overallMinimumPublic,
    overallMaximumPublic,
    lowestQuartileAverageNoZeros,
    highestQuartileAverageNoZeros,
    middleHalfMeanNoZeros,
    overallAverageNoZeros,
  } = data;

  const publicMin = Math.min(
    overallMinimumPublic,
    lowestQuartileAverageNoZeros,
  );
  const min = calculateMin(publicMin);
  const publicMax = Math.max(
    overallMaximumPublic,
    highestQuartileAverageNoZeros,
  );
  const max = calculateMax(publicMax);

  const isMaximumsEqual =
    overallMaximumPublic === highestQuartileAverageNoZeros;

  const offset = (publicMax - publicMin) * 0.08;
  const lowestQuartileValue = lowestQuartileAverageNoZeros;
  const lowestQuartileValueWithMultiplier = lowestQuartileValue - offset;
  const highestQuartileValue = isMaximumsEqual
    ? max
    : highestQuartileAverageNoZeros;
  const highestQuartileValueWithMultiplier = highestQuartileValue + offset;

  return {
    publicMin,
    publicMax,
    min,
    max,
    lowestQuartileValue:
      lowestQuartileValueWithMultiplier < min
        ? lowestQuartileValue
        : lowestQuartileValueWithMultiplier,
    highestQuartileValue:
      highestQuartileValue > max
        ? highestQuartileValue
        : highestQuartileValueWithMultiplier,
    meanValue: overallAverageNoZeros,
    middleHalfMeanValue: middleHalfMeanNoZeros,
  };
};

const generateLabels = (chart: Chart) => {
  const {
    data: { datasets },
  } = chart;

  const uniqueLabelDatasets = uniqBy(datasets, (item) => item.label);
  return uniqueLabelDatasets.map(
    (item) =>
      ({
        text: item.label ?? '',
        fillStyle: item.backgroundColor,
        strokeStyle: item.borderColor,
        lineWidth: item.borderWidth,
        fontColor: '#898E9A',
      }) as LegendItem,
  );
};

export const buildAnnotations = (
  data: IRequiredStatistics,
): Array<AnnotationOptions> => {
  const {
    publicMin,
    publicMax,
    min,
    max,
    highestQuartileValue,
    lowestQuartileValue,
    meanValue,
    middleHalfMeanValue,
  } = getDataValues(data);

  const annotations: Array<AnnotationOptions> = [
    // start of graph value
    {
      id: 'startOfGraphValue',
      type: 'label',
      content: data.bottomConfidentialCasesCount
        ? ''
        : formatCurrency(publicMin),
      xScaleID: 'x',
      xValue: min,
      yAdjust: 30,
      font: {
        family: 'Lato',
        size: 16,
        weight: '600',
      },
      position: {
        x: 'start',
        y: '0%',
      },
      z: 2,
    },

    {
      id: 'startOfGraphValueLabel',
      type: 'label',
      content: ['Low value'],
      xScaleID: 'x',
      xMin: min,
      xMax: min,
      yAdjust: 70,
      font: {
        family: 'Lato',
        size: 14,
        weight: '500',
      },
      position: (context: PartialEventContext) =>
        calculateLabelPosition(context, 'start'),
    },

    // end of graph value
    {
      id: 'endOfGraphValue',
      type: 'label',
      content: data.topConfidentialCasesCount ? '' : formatCurrency(publicMax),
      xScaleID: 'x',
      xValue: max,
      yAdjust: 21,
      font: {
        family: 'Lato',
        size: 16,
        weight: '600',
      },
      position: {
        x: 'end',
        y: '0%',
      },
      z: 2,
    },
    //start of graph
    {
      id: 'startOfGraph',
      type: 'line',
      xScaleID: 'x',
      xMin: min,
      xMax: min,
      borderColor: '#898E9A',
      borderWidth: 4,
      yMin: -0.039,
      yMax: 0.039,
      z: 1,
    },
    //end of graph
    {
      id: 'endOfGraph',
      type: 'line',
      xScaleID: 'x',
      xMin: max,
      xMax: max,
      borderColor: '#898E9A',
      borderWidth: 4,
      yMin: -0.039,
      yMax: 0.039,
      z: 3,
    },

    // lowestQuartileAverage label
    {
      id: 'lowestQuartileAverageLabel',
      type: 'label',
      content: formatCurrency(data.lowestQuartileAverageNoZeros),
      xScaleID: 'x',
      xValue: lowestQuartileValue,
      yAdjust: -45,
      font: {
        family: 'Lato',
        size: 16,
        weight: '600',
      },
      position: (context: PartialEventContext) =>
        calculateLabelPosition(context, 'center'),
    },
    // highestQuartileAverage label
    {
      id: 'highestQuartileAverageLabel',
      type: 'label',
      content: formatCurrency(data.highestQuartileAverageNoZeros),
      xScaleID: 'x',
      xValue: highestQuartileValue,
      yAdjust: -45,
      font: {
        family: 'Lato',
        size: 16,
        weight: '600',
      },
      position: (context: PartialEventContext) =>
        calculateLabelPosition(context, 'center'),
    },
    // start of acceptable range line
    {
      id: 'startOfAcceptableRange',
      type: 'line',
      xScaleID: 'x',
      xMin: lowestQuartileValue,
      xMax: lowestQuartileValue,
      borderColor: '#E5B045',
      borderWidth: 2,
      yScaleID: 'annotationY',
      yMin: -0.1,
      yMax: 0.039,
      z: 2,
    },
    {
      id: 'startOfAcceptableRangeLabel',
      type: 'label',
      content: ['Lowest Quartile', 'Mean'],
      xScaleID: 'x',
      xMin: lowestQuartileValue,
      xMax: lowestQuartileValue,
      yScaleID: 'annotationY',
      yAdjust: -80,
      font: {
        family: 'Lato',
        size: 14,
        weight: '500',
      },
      z: 2,
      position: (context: PartialEventContext) =>
        calculateLabelPosition(context, 'center'),
    },
    // end of acceptable range line
    {
      id: 'endOfAcceptableRangeLabel',
      type: 'label',
      content: ['Highest Quartile', 'Mean'],
      xScaleID: 'x',
      xMin: highestQuartileValue,
      xMax: highestQuartileValue,
      yScaleID: 'annotationY',
      yAdjust: -80,
      font: {
        family: 'Lato',
        size: 14,
        weight: '500',
      },
      z: 2,
      position: (context: PartialEventContext) =>
        calculateLabelPosition(context, 'center'),
    },
    {
      id: 'endOfAcceptableRange',
      type: 'line',
      xScaleID: 'x',
      xMin: highestQuartileValue,
      xMax: highestQuartileValue,
      borderColor: '#E5B045',
      borderWidth: 2,
      yMin: -0.1,
      yMax: 0.039,
      z: 2,
    },
  ];

  if (data.bottomConfidentialCasesCount) {
    const center = 10 ** ((Math.log10(min) + Math.log10(publicMin)) / 2);

    annotations.push(
      // count of bottom confidential cases
      {
        id: 'bottomConfidentialCasesCount',
        type: 'label',
        content: String(data.bottomConfidentialCasesCount),
        xScaleID: 'x',
        xValue: center,
        yAdjust: -36,
        color: '#BCBEC3',
        font: {
          family: 'Lato',
          size: 16,
          weight: '500',
        },
        position: (context: PartialEventContext) => ({
          ...calculateLabelPosition(context, 'center'),
          y: '0%',
        }),
      },
      // start tendril of bottom confidential cases
      {
        id: 'startOfBottomConfidentialCasesTendril',
        type: 'line',
        xScaleID: 'x',
        xMin: min,
        xMax: min,
        borderColor: '#898E9A',
        borderWidth: 4,
        yMin: -0.039,
        yMax: 0.039,
        z: 1,
      },
      // end tendril of bottom confidential cases
      {
        id: 'endOfBottomConfidentialCasesTendril',
        type: 'line',
        xScaleID: 'x',
        xMin: data.overallMinimumPublic,
        xMax: data.overallMinimumPublic,
        borderColor: '#898E9A',
        borderWidth: 2,
        yMin: -0.039,
        yMax: 0.1,
        z: 2,
      },
      // value of bottom confidential cases
      {
        id: 'bottomConfidentialCasesValue',
        type: 'label',
        content: formatCurrency(data.overallMinimumPublic),
        xScaleID: 'x',
        xValue: data.overallMinimumPublic,
        yAdjust: 45,
        font: {
          family: 'Lato',
          size: 16,
          weight: '600',
        },
        position: (context: PartialEventContext) =>
          calculateLabelPosition(context, 'center'),
      },
    );
    // backup: optional rendering for: (data.overallMinimumPublic < data.lowestQuartileAverageNoZeros)
  }

  if (data.topConfidentialCasesCount) {
    const center = 10 ** ((Math.log10(max) + Math.log10(publicMax)) / 2);

    annotations.push(
      // count of top confidential cases
      {
        id: 'topConfidentialCasesCount',
        type: 'label',
        content: String(data.topConfidentialCasesCount),
        xScaleID: 'x',
        xValue: center,
        yAdjust: -36,
        color: '#BCBEC3',
        font: {
          family: 'Lato',
          size: 16,
          weight: '500',
        },
        position: (context: PartialEventContext) => ({
          ...calculateLabelPosition(context, 'center'),
          y: '0%',
        }),
      },
      // start tendril of top confidential cases
      {
        id: 'startOfBottomConfidentialCasesTendril',
        type: 'line',
        xScaleID: 'x',
        xMin: data.overallMaximumPublic,
        xMax: data.overallMaximumPublic,
        borderColor: '#898E9A',
        borderWidth: 2,
        yScaleID: 'annotationY',
        yMin: -0.039,
        yMax: 0.1,
        z: 1,
      },
      {
        id: 'startOfBottomConfidentialCasesTendrilLabel',
        type: 'label',
        content: ['High Value'],
        xScaleID: 'x',
        xMin: data.overallMaximumPublic,
        xMax: data.overallMaximumPublic,
        yScaleID: 'annotationY',
        yAdjust: 70,
        font: {
          family: 'Lato',
          size: 14,
          weight: '500',
        },
        z: 2,
        position: (context: PartialEventContext) =>
          calculateLabelPosition(context, 'center'),
      },
      // end tendril of top confidential cases
      {
        id: 'endOfBottomConfidentialCasesTendril',
        type: 'line',
        xScaleID: 'x',
        xMin: max,
        xMax: max,
        borderColor: '#898E9A',
        borderWidth: 4,
        yMin: -0.039,
        yMax: 0.039,
        z: 1,
      },
      // value of top confidential cases
      {
        id: 'topConfidentialCasesValue',
        type: 'label',
        content: formatCurrency(data.overallMaximumPublic),
        xScaleID: 'x',
        xValue: data.overallMaximumPublic,
        yAdjust: 45,
        font: {
          family: 'Lato',
          size: 16,
          weight: '600',
        },
        position: (context: PartialEventContext) =>
          calculateLabelPosition(context, 'center'),
      },
    );
    //backup: confidential rendering for: (data.overallMaximumPublic > data.highestQuartileAverageNoZeros)
  }
  if (data.middleHalfMeanNoZeros) {
    annotations.push(
      {
        id: 'middleHalfMeanLabelText',
        type: 'label',
        content: ['Middle-Half', 'Mean'],
        xScaleID: 'x',
        xMin: middleHalfMeanValue,
        xMax: middleHalfMeanValue,
        yAdjust: -80,
        font: {
          family: 'Lato',
          size: 14,
          weight: '500',
        },
        position: (context: PartialEventContext) =>
          calculateLabelPosition(context, 'center'),
      },
      {
        id: 'middleHalfMeanLabel',
        type: 'label',
        content: formatCurrency(middleHalfMeanValue),
        xScaleID: 'x',
        xMin: middleHalfMeanValue,
        xMax: middleHalfMeanValue,
        yAdjust: -45,
        font: {
          family: 'Lato',
          size: 14,
          weight: '600',
        },
      },
      {
        id: 'middleHalfMeanValueLabel',
        type: 'line',
        xScaleID: 'x',
        xMin: middleHalfMeanValue,
        xMax: middleHalfMeanValue,
        borderColor: '#000000',
        borderWidth: 2,
        yScaleID: 'annotationY',
        yMin: -0.1,
        yMax: 0.039,
        z: 2,
      },
    );
  }

  if (data.overallAverageNoZeros) {
    annotations.push(
      {
        id: 'meanLabelText',
        type: 'label',
        content: ['Mean'],
        xScaleID: 'x',
        xMin: meanValue,
        xMax: meanValue,
        yAdjust: 70,
        font: {
          family: 'Lato',
          size: 14,
          weight: '500',
        },
        position: (context: PartialEventContext) =>
          calculateLabelPosition(context, 'center'),
      },
      {
        id: 'meanLabel',
        type: 'label',
        content: formatCurrency(meanValue),
        xScaleID: 'x',
        xMin: meanValue,
        xMax: meanValue,
        yAdjust: 45,
        font: {
          family: 'Lato',
          size: 16,
          weight: '600',
        },
      },
      {
        id: 'meanValueLabel',
        type: 'line',
        xScaleID: 'x',
        xMin: meanValue,
        xMax: meanValue,
        borderColor: '#000000',
        borderWidth: 2,
        yScaleID: 'annotationY',
        z: 2,
        yMin: -0.039,
        yMax: 0.1,
      },
    );
  }
  return annotations;
};

const MIN_DISPLAYABLE_VALUE = 0;

export const prepareStatisticsData = (data: IRequiredStatistics) => {
  let entries = toPairs(data);

  entries = entries.map(([key, value]) => {
    const shouldNotModify = [
      'bottomConfidentialCasesCount',
      'topConfidentialCasesCount',
    ].includes(key);

    if (shouldNotModify) {
      return [key, value];
    }

    const currentValue = value || MIN_DISPLAYABLE_VALUE;
    return [key, currentValue];
  });

  return fromPairs(entries) as IRequiredStatistics;
};

export const buildOptions = (
  data: IRequiredStatistics,
  annotations: Array<AnnotationOptions>,
): ChartOptions<'bar'> => {
  const { min, max } = getDataValues(data);

  return {
    responsive: true,
    maintainAspectRatio: false,
    indexAxis: 'y',
    animation: false,
    scales: {
      annotationY: {
        display: false,
        stacked: true,
        axis: 'y',
      },
      y: {
        display: false,
      },
      x: {
        type: 'logarithmic',
        position: 'center',
        border: {
          width: 2,
          color: '#898E9A',
        },
        ticks: {
          display: false,
        },
        min,
        max,
        grid: {
          display: false,
        },
      },
    },
    plugins: {
      annotation: {
        clip: false,
        annotations,
      },
      title: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
      legend: {
        display: true,
        position: 'bottom',
        align: 'start',
        onClick: noop,
        labels: {
          generateLabels,
          boxWidth: 24,
          boxHeight: 24,
          font: {
            family: 'Lato',
            size: 16,
            weight: '300',
          },
        },
      },
    },
  };
};

export const buildData = (
  data: IRequiredStatistics,
  t: TFunction,
): ChartData<'bar'> => {
  const { lowestQuartileValue, highestQuartileValue, publicMin, publicMax } =
    getDataValues(data);

  const datasets: Array<ChartDataset<'bar'>> = [
    {
      type: 'bar',
      label: t('acceptableRangeLegend'),
      data: [[lowestQuartileValue, highestQuartileValue]],
      borderWidth: 2,
      borderColor: '#E5B045',
      backgroundColor: '#FFEBC2',
      borderSkipped: false,
      barPercentage: 0.1,
      datalabels: {
        display: false,
      },
    },
  ];
  // if (data.overallAverageNoZeros) {
  //   datasets.push({
  //     type: 'bar',
  //     label: t('meanLabel'),
  //     data: [[meanValue, meanValue]],
  //     borderWidth: 2,
  //     borderColor: '#70A1FF',
  //     backgroundColor: '#A6CEFF',
  //     borderSkipped: false,
  //     barPercentage: 0.05,
  //     datalabels: { display: false },
  //   });
  // }

  // if (data.middleHalfMeanNoZeros) {
  //   datasets.push({
  //     type: 'bar',
  //     label: t('middleHalfMeanLabel'),
  //     data: [[middleHalfMeanValue, middleHalfMeanValue]],
  //     borderWidth: 2,
  //     borderColor: '#9BD0F5',
  //     backgroundColor: '#CFEFFF',
  //     borderSkipped: false,
  //     barPercentage: 0.05,
  //     datalabels: { display: false },
  //   });
  // }
  if (data.bottomConfidentialCasesCount) {
    datasets.push({
      type: 'bar',
      label: t('confidentialCasesLegend'),
      data: [[calculateMin(publicMin), publicMin]],
      borderWidth: 2,
      borderColor: '#BCBEC3',
      backgroundColor: '#DDDFE3',
      borderSkipped: false,
      barPercentage: 0.1,
      datalabels: {
        display: false,
      },
    });
  }

  if (data.topConfidentialCasesCount) {
    datasets.push({
      type: 'bar',
      label: t('confidentialCasesLegend'),
      data: [[publicMax, calculateMax(publicMax)]],
      borderWidth: 2,
      borderColor: '#BCBEC3',
      backgroundColor: '#DDDFE3',
      borderSkipped: false,
      barPercentage: 0.1,
      datalabels: {
        display: false,
      },
    });
  }

  return {
    labels: [''],
    datasets,
  };
};

const CONFIDENTIAL_COUNT_WIDTH_RATIO = 0.3;

const calculateMin = (publicMin: number) => {
  // If the order of public min is greater or equal than zero,
  // the distance between min value and public min value is equal to
  // width of one order of magnitude multiplied by CONFIDENTIAL_COUNT_WIDTH_RATIO.
  return Math.log10(publicMin) < 1
    ? MIN_DISPLAYABLE_VALUE
    : 10 ** (Math.log10(publicMin) - CONFIDENTIAL_COUNT_WIDTH_RATIO);
};

const calculateMax = (publicMax: number) => {
  // The distance between public max and max value is equal to
  // width of one order of magnitude multiplied by CONFIDENTIAL_COUNT_WIDTH_RATIO.
  return 10 ** (Math.log10(publicMax) + CONFIDENTIAL_COUNT_WIDTH_RATIO);
};
