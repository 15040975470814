import { zodResolver } from '@hookform/resolvers/zod';
import { Grid } from '@mui/material';
import isEmpty from 'lodash/isEmpty';
import { parse } from 'qs';
import { FormProvider } from 'react-hook-form';
import { useLocation } from 'react-router-dom';

import { PropertyKey } from '@bvi/api-interfaces/entity/case-property';
import { ICaseStatisticsData } from '@bvi/api-interfaces/response/case';
import { ISettingsData } from '@bvi/api-interfaces/response/case-settings';

import {
  IGetSettingsQuery,
  ISearchFormDataSchema,
  buildValidationSchema,
  mapParametersToSearchFormData,
  OnOptionsLoad,
} from '../../lib';
import { useFormAsyncValues } from '../../lib/useFormAsyncValues';
import { InitialFilter } from '../InitialFilter';
import { RefineFilter } from '../RefineFilter';

type SearchFiltersProperties = {
  onLoadSettings: (data: IGetSettingsQuery) => Promise<ISettingsData>;
  showOnlyInitialFilter?: boolean;
  profileExists: boolean;
  statisticsData: ICaseStatisticsData;
  isFetching: boolean;
};

const createArrayQuery = (
  array?: Array<{
    id: number;
    name: string;
  }>,
) => {
  if (isEmpty(array)) {
    return;
  }

  return array?.map(({ id }) => id).join(',');
};

export const SearchFilters: React.FC<SearchFiltersProperties> = (
  properties,
) => {
  const {
    onLoadSettings,
    showOnlyInitialFilter,
    profileExists,
    statisticsData,
    isFetching,
  } = properties;

  const location = useLocation();
  const searchParameters = parse(location.search, {
    comma: true,
    ignoreQueryPrefix: true,
  }) as Record<keyof ISearchFormDataSchema, string>;

  const handleLoadSettings = async (
    entity: PropertyKey,
    query: {
      page: number;
      search?: string;
      ids?: Array<number>;
      countryIds?: ISearchFormDataSchema['country'];
      regionIds?: ISearchFormDataSchema['region'];
    },
  ) => {
    const result = await onLoadSettings({
      includes: [entity],
      pagination: {
        [entity]: {
          page: query.page,
          limit: 100,
          countryIds: createArrayQuery(query.countryIds),
          regionIds: createArrayQuery(query.regionIds),
          ids: query.ids?.join(','),
          ...(query.search && {
            names: query.search,
          }),
        },
      },
    });

    const currentResult = result[entity];

    return {
      data: {
        payload: {
          data: currentResult?.items ?? [],
          meta: currentResult?.meta,
        },
      },
    };
  };

  const validationSchema = buildValidationSchema();

  const methods = useFormAsyncValues({
    resolver: zodResolver(validationSchema),
    defaultValues: mapParametersToSearchFormData(searchParameters),
    getSettings: onLoadSettings,
    searchParameters,
  });

  return (
    <FormProvider {...methods}>
      <Grid item xs={12}>
        <InitialFilter
          isFormLoading={methods.isFormLoading}
          onOptionsLoad={handleLoadSettings as OnOptionsLoad}
          statisticsData={statisticsData}
          isFetching={isFetching}
        />
      </Grid>
      {!showOnlyInitialFilter && (
        <Grid item xs={3} my={3}>
          <RefineFilter
            isFormLoading={methods.isFormLoading}
            onOptionsLoad={handleLoadSettings as OnOptionsLoad}
            profileExists={profileExists}
          />
        </Grid>
      )}
    </FormProvider>
  );
};
