import {
  Autocomplete as MuiAutocomplete,
  TextField,
  FormHelperText,
  InputLabel,
  ListItem,
  Chip,
  AutocompleteRenderGetTagProps,
  AutocompleteRenderInputParams,
  TextFieldVariants,
  FormControl,
  Tooltip,
  AutocompleteChangeReason,
} from '@mui/material';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import { useState, ReactNode } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { Div } from '../Div';
import { HelpTooltip } from '../HelpTooltip';
import { Hightlight } from '../Hightlight';
import { Image } from '../Image';
import { info } from '../icons';

import { styles } from './styles';

export type AutocompleteValue = {
  id: number | string;
  name?: string;
  description?: string;
};

type Select2 = {
  name: string;
  label?: string;
  error?: boolean;
  disabled?: boolean;
  required?: boolean;
  helperText?: string | ReactNode;
  placeholder?: string;
  multiple?: boolean;
  variant?: TextFieldVariants;
  fullWidth?: boolean;
  items?: Array<AutocompleteValue>;
  resetOnChange?: () => void;
};

const LIMIT_TEXT_LENGTH = 9;
const getLimitTagsText = (more: number) =>
  more > LIMIT_TEXT_LENGTH ? `${LIMIT_TEXT_LENGTH}+` : `+${more}`;

const hasTooltip = (field: string) => {
  if (field == 'Age Category') {
    field = 'AgeCategory';
  }
  const tips = {
    AgeCategory:
      'For more specific age category searches that reflect a period of abuse that spans different age categories, see "Refine Age Category" under the Advanced Search fields to the left',
  };
  if (tips.hasOwnProperty(field)) {
    return tips[field as keyof typeof tips];
  }

  return false;
};

export function Select2(properties: Select2): JSX.Element {
  const {
    name,
    label,
    error,
    helperText,
    placeholder = 'Start typing...',
    multiple = false,
    variant = 'outlined',
    fullWidth = true,
    required,
    disabled,
    items = [],
    resetOnChange,
  } = properties;
  const [open, setOpen] = useState<boolean>(false);
  const { control, setFocus } = useFormContext();
  const [searchString, setSearchString] = useState<string>('');

  const handleGetOptionLabel = (option: AutocompleteValue) => {
    if (multiple === true) {
      const currentOption = items.find((item) => item.id == option.id);
      return currentOption?.name ?? '';
    }

    //@ts-expect-error task #138
    const currentOption = items.find((item) => item.id == option);
    return currentOption?.name ?? '';
  };

  const handleResetSearchString = () => {
    setSearchString('');
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value: inputValue } = event?.target;

    setSearchString(inputValue);
  };

  const handleRenderOption = (
    optionProperties: React.HTMLAttributes<HTMLLIElement>,
    option: AutocompleteValue,
  ) => {
    const { id, name: optionLabel = '', description } = option;

    return (
      <ListItem {...optionProperties} key={id} sx={styles.listItem}>
        <Hightlight value={optionLabel} filter={searchString} />
        {description && (
          <Tooltip
            title={description}
            enterDelay={500}
            sx={styles.descriptionIcon}
          >
            <Image src={info} alt="info" />
          </Tooltip>
        )}
      </ListItem>
    );
  };

  const renderTags = (
    tagValues: Array<unknown>,
    getTagProperties: AutocompleteRenderGetTagProps,
  ) => {
    return tagValues.map((tag, index: number) => {
      const currentTag = items.find((item) => item.id === tag);
      const tagLabel = currentTag?.name ?? '';

      return (
        <Tooltip title={tagLabel}>
          <Chip
            {...getTagProperties({ index })}
            variant="filled"
            label={tagLabel}
            sx={styles.chip}
          />
        </Tooltip>
      );
    });
  };

  const handleOnChange = async (
    _event: React.SyntheticEvent,
    value: AutocompleteValue,
    reason: AutocompleteChangeReason,
    onChange: (...event: Array<unknown>) => void,
  ) => {
    const isClearReason = reason === 'clear';
    await resetOnChange?.();
    if (Array.isArray(value)) {
      onChange(value.map((item) => item?.id || item));
    } else {
      onChange(value?.id ?? null);
    }

    if (isClearReason) {
      setOpen(true);
      setFocus(name);
    }
  };

  const renderTextField = (
    inputParameters: AutocompleteRenderInputParams,
    textFieldPlaceholder: string,
  ) => {
    if (multiple) {
      return (
        <Div sx={styles.textFieldWrapper}>
          <TextField
            {...inputParameters}
            value={searchString}
            size="medium"
            variant={variant}
            error={error}
            onChange={handleInputChange}
            sx={styles.multipleTextField}
          />
          {!searchString && textFieldPlaceholder && (
            <Div sx={styles.placeholder}>{textFieldPlaceholder}</Div>
          )}
        </Div>
      );
    }

    return (
      <TextField
        {...inputParameters}
        value={searchString}
        size="medium"
        variant={variant}
        error={error}
        placeholder={textFieldPlaceholder}
        onChange={handleInputChange}
        sx={styles.singleTextField}
      />
    );
  };

  return (
    <FormControl fullWidth={fullWidth}>
      {label && (
        <div style={{ paddingBottom: '5px' }}>
          <InputLabel variant={variant} required={required}>
            {label}
            {hasTooltip(label) && <HelpTooltip title={hasTooltip(label)} />}
          </InputLabel>
        </div>
      )}

      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value } }) => {
          const isValueEmpty = isNil(value) || isEmpty(value);
          const currentPlaceholder = isValueEmpty ? placeholder : '';

          return (
            <MuiAutocomplete
              open={open}
              onOpen={() => {
                setOpen(true);
              }}
              value={value}
              onClose={() => {
                handleResetSearchString();
                setOpen(false);
              }}
              limitTags={2}
              getLimitTagsText={getLimitTagsText}
              isOptionEqualToValue={(
                option: AutocompleteValue,
                optionValue: unknown,
              ) => option.id == optionValue}
              getOptionLabel={handleGetOptionLabel}
              options={items}
              onChange={(event, value, reason) =>
                handleOnChange(
                  event,
                  value as AutocompleteValue,
                  reason,
                  onChange,
                )
              }
              disabled={disabled}
              renderTags={renderTags}
              multiple={multiple}
              disableCloseOnSelect={multiple}
              renderOption={handleRenderOption}
              noOptionsText="No options"
              renderInput={(inputParameters: AutocompleteRenderInputParams) =>
                renderTextField(inputParameters, currentPlaceholder)
              }
            />
          );
        }}
      />

      {error && <FormHelperText error>{helperText}</FormHelperText>}
    </FormControl>
  );
}
