import isEmpty from 'lodash/isEmpty';

import {
  AbuseDetailsKeys,
  EIncludeExcludeKeys,
  ICasePropertyValue,
  LegalDetailsKeys,
  ResolutionPublicityVariantsKey,
  StaticPropertyKey,
  VictimInfoKeys,
} from '@bvi/api-interfaces/entity/case-property';
import { AdditionalFilterParameter } from '@bvi/api-interfaces/request/case';

import { ICaseFormRequest } from '../types';

const mapLevel = (data: ICaseFormRequest, level: AbuseDetailsKeys) => {
  const currentLevel = data.properties?.[level] as ICasePropertyValue<
    Array<string>
  >;
  if (isEmpty(currentLevel?.value)) {
    return;
  }

  return currentLevel?.value.join(',');
};

export const mapCaseFormDataToSearch = (
  data: ICaseFormRequest,
): Record<string, unknown> => {
  const resolutionPublicity = data.resolution?.isPublic
    ? ResolutionPublicityVariantsKey.PUBLIC
    : ResolutionPublicityVariantsKey.CONFIDENTIAL;

  return {
    [StaticPropertyKey.COUNTRY]: data.countryId,
    [StaticPropertyKey.REGION]: data.regionId,
    [StaticPropertyKey.STATE]: data.stateId,
    [VictimInfoKeys.AGE_CATEGORY]:
      data.properties?.[VictimInfoKeys.AGE_CATEGORY]?.value,
    [VictimInfoKeys.GENDER]: data.properties?.[VictimInfoKeys.GENDER]?.value,
    [StaticPropertyKey.ORGANIZATION]: data.organizationId,
    [StaticPropertyKey.DIOCESE]: data.dioceseId,
    [StaticPropertyKey.ORGANIZATION_TYPE]: data.organizationTypeId,
    [VictimInfoKeys.AGE_AT_START_OF_ABUSE]:
      data.properties?.[VictimInfoKeys.AGE_AT_START_OF_ABUSE]?.value,
    [VictimInfoKeys.AGE_AT_END_OF_ABUSE]:
      data.properties?.[VictimInfoKeys.AGE_AT_END_OF_ABUSE]?.value,
    [VictimInfoKeys.STARTED_ABUSE_AT]:
      data.properties?.[VictimInfoKeys.STARTED_ABUSE_AT]?.value,
    [VictimInfoKeys.END_OF_ABUSE_AT]:
      data.properties?.[VictimInfoKeys.END_OF_ABUSE_AT]?.value,
    [AbuseDetailsKeys.NUMBER_OF_INCIDENTS_LOW]:
      data.properties?.[AbuseDetailsKeys.NUMBER_OF_INCIDENTS_LOW]?.value,
    [AbuseDetailsKeys.NUMBER_OF_INCIDENTS_HIGH]:
      data.properties?.[AbuseDetailsKeys.NUMBER_OF_INCIDENTS_HIGH]?.value,
    [AbuseDetailsKeys.LEVEL_1]: mapLevel(data, AbuseDetailsKeys.LEVEL_1),
    [AbuseDetailsKeys.LEVEL_2]: mapLevel(data, AbuseDetailsKeys.LEVEL_2),
    [AbuseDetailsKeys.LEVEL_3]: mapLevel(data, AbuseDetailsKeys.LEVEL_3),
    [AbuseDetailsKeys.LEVEL_4]: mapLevel(data, AbuseDetailsKeys.LEVEL_4),
    [AbuseDetailsKeys.LEVEL_5]: mapLevel(data, AbuseDetailsKeys.LEVEL_5),
    [StaticPropertyKey.JOB_TITLE]: data.jobTitleId,
    [StaticPropertyKey.ORDER]: data.orderId,
    [AbuseDetailsKeys.NUMBER_OF_PERPETRATORS_LOW]:
      data.properties?.[AbuseDetailsKeys.NUMBER_OF_PERPETRATORS_LOW]?.value,
    [AbuseDetailsKeys.NUMBER_OF_PERPETRATORS_HIGH]:
      data.properties?.[AbuseDetailsKeys.NUMBER_OF_PERPETRATORS_HIGH]?.value,
    [StaticPropertyKey.LIABILITY_DEFENSES]: data.liabilityDefensesIds,
    [StaticPropertyKey.EXCEPTION]: data.exceptionId,
    [LegalDetailsKeys.PREPARATOR]:
      data.properties?.[LegalDetailsKeys.PREPARATOR]?.value,
    [StaticPropertyKey.RESOLUTION_TYPE]: data.resolution?.typeId,
    [LegalDetailsKeys.LAWSUIT_FILED]:
      data.properties?.[LegalDetailsKeys.LAWSUIT_FILED]?.value,
    [LegalDetailsKeys.HAS_ATTORNEY]:
      data.properties?.[LegalDetailsKeys.HAS_ATTORNEY]?.value,
    [AdditionalFilterParameter.RESOLUTION_DATE_LOW]:
      data.resolution?.resolutionDate?.getTime(),
    [AdditionalFilterParameter.RESOLUTION_PUBLICITY]:
      data.resolution && resolutionPublicity,
    [EIncludeExcludeKeys.CLASS_ACTION_NAME]:
      data.properties?.[EIncludeExcludeKeys.CLASS_ACTION_NAME]?.value,
    [EIncludeExcludeKeys.BANKRUPTCY_NAME]:
      data.properties?.[EIncludeExcludeKeys.BANKRUPTCY_NAME]?.value,
    [EIncludeExcludeKeys.COMPENSATION_REDRESS_NAME]:
      data.properties?.[EIncludeExcludeKeys.COMPENSATION_REDRESS_NAME]?.value,
  };
};
