import { Grid, Paper, Typography, Button, Box } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  StaticPropertyKey,
  VictimInfoKeys,
} from '@bvi/api-interfaces/entity/case-property';
import { AdditionalFilterParameter } from '@bvi/api-interfaces/request/case';
import { ICaseStatisticsData } from '@bvi/api-interfaces/response/case';
import { Autocomplete, Select2 } from '@bvi/common-components';
import { useFormOptions } from '@bvi/form-utils';

import {
  ISearchFormDataSchema,
  ITerritoryValue,
  OnOptionsLoad,
  useSearchContext,
  parentAgeCategories,
  formatNumber,
} from '../..';
import { getUniqueLocations } from '../../lib/getUniqLocations';
import { i18nInstance } from '../../locales';

import { styles } from './styles';

type InitialFilterProperties = {
  onOptionsLoad: OnOptionsLoad;
  isFormLoading?: boolean;
  statisticsData: ICaseStatisticsData;
  isFetching: boolean;
};

export const InitialFilter: React.FC<InitialFilterProperties> = (
  properties,
) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { onOptionsLoad, isFormLoading, statisticsData, isFetching } =
    properties;

  const { onSubmit, includeStatisticsData } = useSearchContext();
  const { t } = useTranslation('', {
    i18n: i18nInstance,
  });

  const {
    formState: { errors },
    getValues,
    reset,
    resetField,
    handleSubmit,
  } = useFormContext<ISearchFormDataSchema>();

  const ageCategoryOptions = useFormOptions(
    parentAgeCategories,
    'ageCategory',
    'ageCategoryDescription',
  );

  const handleResetOnRegionChange = (value: Array<ITerritoryValue>) => {
    const selectedCountries = getValues(StaticPropertyKey.COUNTRY);
    const countries = value.map((region) => region.country).filter(Boolean);

    const { shouldUpdate: shouldUpdateCountries, list: uniqCountries } =
      getUniqueLocations(selectedCountries, countries);

    if (shouldUpdateCountries) {
      resetField(StaticPropertyKey.COUNTRY, {
        defaultValue: uniqCountries,
      });
    }
    resetField(StaticPropertyKey.STATE);
  };

  const handleResetOnStateChange = (value: Array<ITerritoryValue>) => {
    const selectedCountries = getValues(StaticPropertyKey.COUNTRY);
    const countries = value.map((state) => state.country).filter(Boolean);
    const selectedRegions = getValues(StaticPropertyKey.REGION);
    const regions = value.map((state) => state.region).filter(Boolean);

    const { shouldUpdate: shouldUpdateCountries, list: uniqCountries } =
      getUniqueLocations(selectedCountries, countries);
    const { shouldUpdate: shouldUpdateRegions, list: uniqRegions } =
      getUniqueLocations(selectedRegions, regions);

    if (shouldUpdateCountries) {
      resetField(StaticPropertyKey.COUNTRY, {
        defaultValue: uniqCountries,
      });
    }
    if (shouldUpdateRegions) {
      resetField(StaticPropertyKey.REGION, {
        defaultValue: uniqRegions,
      });
    }
  };

  const handleResetRefineAgeCategory = () => {
    resetField(AdditionalFilterParameter.REFINE_AGE_CATEGORY, {
      defaultValue: [],
    });
  };

  const renderForm = () => {
    return (
      <>
        <Grid container item xs={12} columnSpacing={3} my={2}>
          <Grid item xs={3}>
            <Autocomplete
              name={StaticPropertyKey.COUNTRY}
              onOptionsLoad={async (data) =>
                await onOptionsLoad(StaticPropertyKey.COUNTRY, data)
              }
              resetOnChange={() => {
                reset((formValues) => ({
                  ...formValues,
                  [StaticPropertyKey.REGION]: [],
                  [StaticPropertyKey.STATE]: [],
                }));
              }}
              variant="filled"
              multiple
              isPrefetching={isFormLoading}
              label={t(`form.${StaticPropertyKey.COUNTRY}.label`)}
              placeholder={t(`form.${StaticPropertyKey.COUNTRY}.placeholder`)}
              helperText={<>{errors.country?.message}</>}
              error={Boolean(errors.country)}
            />
          </Grid>
          <Grid item xs={3}>
            <Autocomplete<ITerritoryValue, true>
              name={StaticPropertyKey.REGION}
              onOptionsLoad={async (data) => {
                const countryIds = getValues('country');

                return await onOptionsLoad(StaticPropertyKey.REGION, {
                  ...data,
                  countryIds,
                });
              }}
              resetOnChange={handleResetOnRegionChange}
              variant="filled"
              multiple
              isPrefetching={isFormLoading}
              label={t(`form.${StaticPropertyKey.REGION}.label`)}
              placeholder={t(`form.${StaticPropertyKey.REGION}.placeholder`)}
              helperText={<>{errors[StaticPropertyKey.REGION]?.message}</>}
              error={Boolean(errors[StaticPropertyKey.REGION])}
            />
          </Grid>
          <Grid item xs={3}>
            <Autocomplete<ITerritoryValue, true>
              name={StaticPropertyKey.STATE}
              onOptionsLoad={async (data) => {
                const [countryIds, regionIds] = getValues([
                  'country',
                  'region',
                ]);

                return await onOptionsLoad(StaticPropertyKey.STATE, {
                  ...data,
                  countryIds,
                  regionIds,
                });
              }}
              resetOnChange={handleResetOnStateChange}
              variant="filled"
              multiple
              isPrefetching={isFormLoading}
              label={t(`form.${StaticPropertyKey.STATE}.label`)}
              placeholder={t(`form.${StaticPropertyKey.STATE}.placeholder`)}
              helperText={<>{errors[StaticPropertyKey.STATE]?.message}</>}
              error={Boolean(errors[StaticPropertyKey.STATE])}
            />
          </Grid>
          <Grid item xs={3}>
            <Select2
              name={VictimInfoKeys.AGE_CATEGORY}
              variant="filled"
              fullWidth
              multiple
              items={ageCategoryOptions}
              label={t(`form.${VictimInfoKeys.AGE_CATEGORY}.label`)}
              placeholder={t(`form.${VictimInfoKeys.AGE_CATEGORY}.placeholder`)}
              helperText={<>{errors[VictimInfoKeys.AGE_CATEGORY]?.message}</>}
              error={Boolean(errors[VictimInfoKeys.AGE_CATEGORY])}
              resetOnChange={handleResetRefineAgeCategory}
            />
          </Grid>
        </Grid>
        <Grid
          container
          item
          xs={12}
          columnSpacing={3}
          my={2}
          alignItems="center"
        >
          <Grid item xs={3}>
            <Autocomplete
              name={StaticPropertyKey.ORGANIZATION_TYPE}
              onOptionsLoad={async (data) =>
                await onOptionsLoad(StaticPropertyKey.ORGANIZATION_TYPE, data)
              }
              variant="filled"
              multiple
              isPrefetching={isFormLoading}
              label={t(`form.${StaticPropertyKey.ORGANIZATION_TYPE}.label`)}
              placeholder={t(
                t(`form.${StaticPropertyKey.ORGANIZATION_TYPE}.placeholder`),
              )}
            />
          </Grid>
          <Grid item xs={3}>
            <Autocomplete
              name={StaticPropertyKey.ORGANIZATION}
              onOptionsLoad={async (data) =>
                await onOptionsLoad(StaticPropertyKey.ORGANIZATION, data)
              }
              variant="filled"
              label={t(`form.${StaticPropertyKey.ORGANIZATION}.label`)}
              placeholder={t(
                `form.${StaticPropertyKey.ORGANIZATION}.placeholder`,
              )}
            />
          </Grid>
          <Grid item xs={3}>
            <Autocomplete
              name={StaticPropertyKey.DIOCESE}
              onOptionsLoad={async (data) =>
                await onOptionsLoad(StaticPropertyKey.DIOCESE, data)
              }
              variant="filled"
              label={t(`form.${StaticPropertyKey.DIOCESE}.label`)}
              placeholder={t(`form.${StaticPropertyKey.DIOCESE}.placeholder`)}
            />
          </Grid>
          <Grid item xs={3} sx={styles.buttonsWrapper}>
            <Button
              variant="contained"
              size="small"
              onClick={handleSubmit(onSubmit)}
            >
              {t('initialFilter.submitButton')}
            </Button>
          </Grid>
        </Grid>
      </>
    );
  };

  return (
    <>
      <Grid xs={12} sx={styles.buttonsWrapper} my={2}>
        <Box sx={styles.caseHadingBoxWrapper}>
          <Typography variant="h4">
            {t('caseHading.title')}
            {formatNumber(includeStatisticsData?.total as number)}
          </Typography>
        </Box>
      </Grid>
      <Paper sx={styles.wrapper}>
        <Grid style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h3">{t('initialFilter.title')}</Typography>
        </Grid>
        <Grid container>{renderForm()}</Grid>
      </Paper>
    </>
  );
};
