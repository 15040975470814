/* eslint-disable unicorn/no-nested-ternary */
import { Box, TableCell, TableHead, TableRow } from '@mui/material';

import { HelpTooltip } from '@bvi/common-components';

import { styles } from '../styles';

type HeaderProperties = {
  cells: Array<{ label: string }>;
};

const hasTooltip = (field: string) => {
  if (field == 'Middle-Half Mean' || field.includes('Half')) {
    field = 'MiddleHalfMean';
  }
  if (field == 'Lowest Quartile Mean') {
    field = 'LowestQuartileMean';
  }
  if (field == 'Highest Quartile Mean') {
    field = 'HighestQuartileMean';
  }
  const tips = {
    Mean: 'Arithmetic Mean (average): Total amount paid in compensation divided by total number of claimants',
    Median:
      'The middle value or the average of the two middle values in the ascending order of resolution values',
    Mode: 'The resolution value that appears most often. There is no Mode shown in the results that include $0 resolution values.',
    MiddleHalfMean:
      'The average of the resolution values of the combined two middle quartiles',
    LowestQuartileMean:
      'The average of the resolution values in the lowest quartile of the ascending order of resolution values',
    HighestQuartileMean:
      'The average of the resolution values in the highest quartile of the ascending order of resolution values',
  };
  if (tips.hasOwnProperty(field)) {
    return tips[field as keyof typeof tips];
  }

  return false;
};

export const ResultsTableHeader: React.FC<HeaderProperties> = (properties) => {
  const { cells } = properties;

  return (
    <TableHead>
      <TableRow>
        {cells.map((cell, index) => (
          <TableCell key={cell.label} sx={styles.headerCell}>
            <Box sx={styles.headerCellContent}>
              {hasTooltip(cell.label) && (
                <Box>
                  <HelpTooltip title={hasTooltip(cell.label)} />
                </Box>
              )}
              <Box
                sx={{
                  ...styles.headerCellLabel,
                  whiteSpace: cell.label.length > 10 ? 'normal' : 'nowrap',
                  width:
                    index === 4
                      ? '100px'
                      : index === 5 || index === 6
                        ? '120px'
                        : index === 7
                          ? '90px'
                          : 'auto',
                }}
              >
                {cell.label}
              </Box>
            </Box>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
