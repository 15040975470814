import { BaseResponse } from '@bvi/api-interfaces/response/base';
import { ICaseStatisticsData } from '@bvi/api-interfaces/response/case';
import { ISettingsData } from '@bvi/api-interfaces/response/case-settings';
import { IGetSettingsQuery } from '@bvi/cases-search';
import { routes } from '@bvi/dashboard/api-routes';
import { TagTypes, apiContainer } from '@bvi/dashboard/shared/api';

import { parametersBuilder } from './parametersBuilder';

export const searchApi = apiContainer.injectEndpoints({
  endpoints: (builder) => ({
    getStatistics: builder.query<BaseResponse<ICaseStatisticsData>, unknown>({
      providesTags(_result, error) {
        return error ? [] : [TagTypes.CASE_STATISTICS];
      },
      query: (parameters) => ({
        url: routes.cases.getStatistics(),
        method: 'GET',
        params: parameters,
      }),
    }),
    getIncludeStatistics: builder.query<
      BaseResponse<ICaseStatisticsData>,
      unknown
    >({
      providesTags(_result, error) {
        return error ? [] : [TagTypes.CASE_STATISTICS];
      },
      query: (parameters) => ({
        url: routes.cases.getStatistics(),
        method: 'GET',
        params: parameters,
      }),
    }),
    getSettings: builder.query<BaseResponse<ISettingsData>, IGetSettingsQuery>({
      query: ({ includes, pagination }) => ({
        url: `${routes.cases.getSettings()}`,
        method: 'GET',
        params: parametersBuilder({ includes, pagination }),
      }),
      providesTags(_result, error) {
        return error ? [] : [TagTypes.CASE_SETTINGS];
      },
    }),
  }),
});

export const {
  useGetStatisticsQuery,
  useLazyGetStatisticsQuery,
  useGetSettingsQuery,
  useLazyGetSettingsQuery,
} = searchApi;
